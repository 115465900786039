import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { ViewType } from '@/models/views';
// If the name's string is longer than 16 characters, it will overflow the text in the component
const MAX_LENGTH = 16;
const MIN_LENGTH = 1;

export const schema = (t: TFunction) =>
  Yup.object({
    name: Yup.string()
      .min(MIN_LENGTH, t('validation.give_name'))
      .max(MAX_LENGTH, t('validation.name_length', { 0: MAX_LENGTH }))
      .required(),
    icon: Yup.string(),
    type: Yup.mixed<ViewType>().oneOf(Object.values(ViewType)).required(),
  });
