import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { useSelector } from 'react-redux';

import { selectAccountId } from '@/redux/session/selectors';
import { X_MOVEO_ACCOUNT_ID } from '@/util/constants';
import { isDevOrLocal } from '@/util/util';

const ApolloProviderWithAccountId = ({ children }) => {
  const accountId = useSelector(selectAccountId);

  if (isDevOrLocal()) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  const client = new ApolloClient({
    uri: '/www/api/v1/graphql',
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
      },
    },
    headers: {
      [X_MOVEO_ACCOUNT_ID]: accountId,
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWithAccountId;
