import { useCallback, useEffect, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import { isPromise } from '@/util/util';

import Modal from './Modal';

type Props = {
  onDelete: () => void;
  name?: string;
  subtitle?: React.ReactNode;
  title?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
} & (
  | {
      confirm?: boolean;
      warning?: never;
      forceLabel?: never;
    }
  | {
      warning?: React.ReactNode;
      forceLabel?: string;
      confirm?: never;
    }
);

function ModalDelete({
  onDelete,
  name,
  subtitle,
  title,
  confirm,
  warning,
  forceLabel,
  primaryButtonText,
  secondaryButtonText,
}: Props) {
  const { t } = useTranslation();
  const [enableButton, setEnableButton] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const valueToType = name || t('common.delete');
  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isPromise(onDelete)) {
        await onDelete();
      } else {
        onDelete();
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }, [onDelete]);

  const onChange = () => {
    setChecked(!checked);
  };

  const handleDeleteOnCharge = useCallback((event) => {
    setDeleteName(event.target.value);
  }, []);

  useEffect(() => {
    if (!confirm && !warning) {
      setEnableButton(true);
    } else if (!confirm && warning && checked) {
      setEnableButton(true);
    } else {
      setEnableButton(
        !!valueToType &&
          deleteName.trim().toUpperCase() === valueToType.trim().toUpperCase()
      );
    }

    if (!!forceLabel && !checked) {
      setEnableButton(false);
    }
  }, [deleteName, valueToType, confirm, warning, checked, forceLabel]);

  const getPrimaryButtonText = useCallback(() => {
    if (primaryButtonText) {
      return primaryButtonText;
    }
    return t('common.delete');
  }, [primaryButtonText, t]);

  return (
    <Modal
      title={title || t('prompts.unsaved_changes.title')}
      subtitle={subtitle || t('prompts.unsaved_changes.subtitle')}
      onPrimarySubmit={handleDelete}
      danger
      primaryButtonText={getPrimaryButtonText()}
      secondaryButtonText={secondaryButtonText || t('common.back_to_safety')}
      primaryButtonDisable={!enableButton}
      autoFocus={enableButton}
      isSubmitting={isLoading}
    >
      {warning}
      {!!forceLabel && (
        <FormControlLabel
          sx={{
            marginTop: 'var(--space-8)',

            '& .MuiFormControlLabel-label': {
              fontWeight: 400,
            },
          }}
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              name="checkbox"
              color="primary"
            />
          }
          label={forceLabel}
        />
      )}
      {!!confirm && (
        <div className="input__container">
          <Input
            placeholder={valueToType}
            size="medium"
            autoFocus
            onChange={handleDeleteOnCharge}
            label={t('common.type_to_delete', { 0: valueToType })}
          />
        </div>
      )}
    </Modal>
  );
}

export default ModalDelete;
