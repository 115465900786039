import { useState } from 'react';

import { EmojiClickData } from 'emoji-picker-react';

import { EmojiURL } from '@/components/atoms/EmojiURL/EmojiURL';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Emoji from '@/components/atoms/Icons/Emoji';
import Input, { InputProps } from '@/components/atoms/Input/Input';

import { EmojiClick, EmojiPicker } from '../../../EmojiPicker/EmojiPicker';

import styles from './EmojiInput.module.scss';

interface EmojiInputProps extends InputProps {
  onEmojiClick: EmojiClick;
  emojiPlaceholder?: string;
  setEmojiMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEmojiMenuOpen: boolean;
}
export const EmojiInput = ({
  onEmojiClick,
  emojiPlaceholder,
  setEmojiMenuOpen,
  isEmojiMenuOpen,
  ...props
}: EmojiInputProps) => {
  const [emoji, setEmoji] = useState<Partial<EmojiClickData> | null>(
    emojiPlaceholder
      ? {
          imageUrl: emojiPlaceholder,
          names: ['default icon'],
        }
      : null
  );
  const handleClickAway = () => {
    setEmojiMenuOpen(false);
  };

  const handleSetEmoji = (emoji: EmojiClickData, event: MouseEvent) => {
    onEmojiClick(emoji, event);
    setEmoji(emoji);
    setEmojiMenuOpen(false);
  };

  const EmojiField = () => {
    return (
      <div className={styles.emojiButton}>
        <IconButton
          ariaLabel="Smiley Face"
          onClick={() => setEmojiMenuOpen((p) => !p)}
        >
          {emoji ? (
            <EmojiURL url={emoji?.imageUrl} alt={emoji?.names[0]} />
          ) : (
            <Emoji color="var(--text-default-gray-light)" />
          )}
        </IconButton>
        {isEmojiMenuOpen && (
          <EmojiPicker
            onEmojiClick={handleSetEmoji}
            width={256}
            height={300}
            onClickAway={handleClickAway}
          />
        )}
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <Input {...props} />
      <EmojiField />
    </div>
  );
};
