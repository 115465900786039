import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { WidgetConfig } from '@/models/webclient';
import { CHANNELS_WS_URL } from '@/util/constants';
import { getWebClientSDK } from '@/util/util';

import { useAccount } from './useAccount';
import useUser from './useUser';

/**
 * The properties to pass to the useWebClient hook. Extends the `WidgetConfig`
 * @param host - The host URL for the WebSocket connection.
 * @param integrationId - The ID of the integration to connect to.
 * @param autoInit - Whether to automatically initialize the WebSocket connection.
 *
 */
type UseWebClientProps = WidgetConfig & {
  host?: string;
  integrationId: string;
  autoInit?: boolean;
};

export const useWebClient = ({
  host = CHANNELS_WS_URL,
  integrationId,
  autoInit,
  ...config
}: UseWebClientProps) => {
  const { user } = useUser();
  const { account } = useAccount();
  const [webClientInstance, setWebClientInstance] = useState(null);
  const webClient = useMemo(() => window.MoveoAI, []);
  const initiatedRef = useRef(false);
  const isScriptLoaded = useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      // if instance is already created resolve
      if (window.MoveoAI) {
        initiatedRef.current = true;
        resolve(true);
      } else {
        // otherwise remove the script and add it again
        const moveoScript = document.getElementById('moveo-script');
        moveoScript?.remove();
        const script = document.createElement('script');
        script.src = getWebClientSDK();
        script.async = true;
        script.id = 'moveo-ai';
        document.body.appendChild(script);

        script.onload = () => {
          initiatedRef.current = true;
          resolve(true);
        };
        script.onerror = () => {
          reject(false);
        };
      }
    });
  }, []);

  const getElement = useCallback((): Promise<HTMLElement> => {
    return new Promise((resolve) => {
      if (!config?.elementId) {
        resolve(null);
      }
      const element = document?.getElementById(`${config.elementId}`);
      if (element) {
        resolve(element);
      } else {
        setTimeout(() => resolve(getElement()), 1000);
      }
    });
  }, [config.elementId]);

  const loadWebClient = useCallback(
    async () =>
      isScriptLoaded()
        .then(() => getElement())
        .then((element) =>
          webClient.init({
            integrationId: `${integrationId}`,
            host,
            element,
            ...config,
          })
        )
        .then((instance) => {
          console.debug('webClientInstance', instance);
          setWebClientInstance(instance);

          instance.onSessionCreated(() => {
            instance.updateContext({
              user: {
                display_name: user?.name,
                email: user?.email,
              },
              accountId: account?.account_id,
              slug: account?.slug,
              ...(config?.language && { tags: [config?.language] }),
            });
          });
        })
        .catch((err) => {
          console.error('error loading web client', err);
        }),
    [
      account?.account_id,
      account?.slug,
      user?.email,
      user?.name,
      webClient,
      host,
      integrationId,
      config,
      getElement,
      isScriptLoaded,
    ]
  );

  // auto init
  useEffect(() => {
    if (
      autoInit &&
      integrationId &&
      !initiatedRef.current &&
      !webClientInstance
    ) {
      loadWebClient();
    }
  }, [
    autoInit,
    config,
    getElement,
    integrationId,
    loadWebClient,
    webClientInstance,
  ]);

  useEffect(() => {
    return () => {
      initiatedRef.current = false;
    };
  }, []);

  const initialiseWebchat = useCallback(() => {
    loadWebClient();
  }, [loadWebClient]);

  return {
    webClient,
    loadWebClient,
    webClientInstance,
    initialiseWebchat,
  };
};
