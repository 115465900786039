export const enum actions {
  READ = 'read',
  READ_OTHERS = 'read.others',
  WRITE = 'write',
  DELETE = 'delete',
  ASSIGN = 'assign',
  UPDATE = 'update',
  OTHERS = 'others',
}

export type Permissions = {
  permissions: Permission[];
};

export type Permission = {
  resource_id: string;
  permission: string;
  allow: boolean;
};

export type PermissionsState = {
  permissions: Permission[];
  isUserOwner: boolean;
};

export type Module =
  | 'accounts'
  | 'analytics'
  | 'audit_logs'
  | 'billing'
  | 'brains'
  | 'broadcasts'
  | 'bundles'
  | 'business_hours'
  | 'collections'
  | 'conversations'
  | 'credentials'
  | 'datasources'
  | 'departments'
  | 'desks'
  | 'dialogs'
  | 'documents'
  | 'entities'
  | 'integrations'
  | 'intents'
  | 'invitations'
  | 'language_models'
  | 'logs'
  | 'roles'
  | 'rules'
  | 'sessions'
  | 'teams'
  | 'versions'
  | 'views'
  | 'webhooks'
  | 'conversations.read.departments';
