import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Condition } from '@/models/condition';
import { TestNumber, WhatsAppIntegration } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  onRuleCreated,
  onRuleUpdated,
  useRules,
  API as rulesAPI,
} from '@/modules/rules/hooks/useRules';
import { Action, ActionType, Rule } from '@/modules/rules/model';
import { getPermissions } from '@/redux/permissions/selectors';

import useBrains from './useBrains';
import useDesks from './useDesks';
import { useIntegrations } from './useIntegrations';

export const getTestRulePayload = (
  t: TFunction,
  testNumber: TestNumberWithDeskId,
  brain_id: string,
  brainName: string,
  existingRule?: Rule,
  isPhoneUpdate?: boolean
) => {
  const basicRuleInfo: Partial<Rule> = {
    rule_id: testNumber?.rule_id,
    desk_id: testNumber.desk_id,
    name: `${t('whatsapp_test.test_rule.name')} ${testNumber.label}`.substring(
      0,
      32
    ),
    status: 'active',
    description: t('whatsapp_test.test_rule.description', {
      0: brainName,
      1: testNumber.phone_number,
    }),
    metadata: {
      source: 'whatsapp_test',
    },
  };
  const assingBrainAction: Action = {
    type: ActionType.ASSIGN_BRAIN,
    brain_version: 0,
    brain_parent_id: brain_id,
  };

  const testPhoneCondition: Condition = {
    operator: 'and',
    conditions: [
      {
        operator: 'or',
        conditions: [
          {
            value: null,
            attribute: 'channel.whatsapp',
            comparison: 'eq',
          },
        ],
      },
      {
        operator: 'or',
        conditions: [
          {
            value: testNumber.phone_number,
            attribute: 'user.phone',
            comparison: 'eq',
          },
        ],
      },
    ],
  };

  let restRuleInfo: Partial<Rule>;

  if (existingRule) {
    restRuleInfo = existingRule;
    const assignBrainActionIndex = restRuleInfo.actions.findIndex(
      (act) => act.type === ActionType.ASSIGN_BRAIN
    );
    // If rule exists update the assign brain action
    if (assignBrainActionIndex >= 0) {
      restRuleInfo.actions[assignBrainActionIndex] = assingBrainAction;
    } else {
      restRuleInfo.actions.push(assingBrainAction);
    }

    if (isPhoneUpdate) {
      restRuleInfo.condition = testPhoneCondition;
    }
  } else {
    restRuleInfo = {
      triggers: [
        {
          type: 'first_message',
        },
      ],
      condition: testPhoneCondition,
      actions: [assingBrainAction],
    };
  }

  return { ...restRuleInfo, ...basicRuleInfo } as Partial<Rule>;
};

const getUpdateIntegrationPayload = (
  selectedNumber: TestNumberWithDeskId,
  integration: WhatsAppIntegration
) => {
  let updatePayload: WhatsAppIntegration | undefined;

  const testNumber = integration.config.test_numbers?.find(
    (tn) => tn.phone_number === selectedNumber.phone_number
  );

  if (
    integration.config.test_numbers?.length === 0 ||
    !integration.config.test_numbers
  ) {
    updatePayload = {
      ...integration,
      config: {
        ...integration.config,
        test_numbers: [selectedNumber],
      },
    };
  } else if (testNumber && testNumber.rule_id != selectedNumber.rule_id) {
    updatePayload = {
      ...integration,
      config: {
        ...integration.config,
        test_numbers: integration?.config?.test_numbers?.map((pn) =>
          pn.phone_number === selectedNumber.phone_number
            ? { ...pn, rule_id: selectedNumber.rule_id }
            : pn
        ),
      },
    };
  } else if (
    !integration.config.test_numbers?.find(
      (tn) =>
        tn.phone_number === selectedNumber.phone_number &&
        tn.rule_id === selectedNumber.rule_id
    )
  ) {
    updatePayload = {
      ...integration,
      config: {
        ...integration.config,
        test_numbers: [...integration.config.test_numbers, selectedNumber],
      },
    };
  }
  return updatePayload;
};

type WhatsAppModalState = 'pending' | 'creating' | 'selecting' | 'testing';

export interface TestNumberWithDeskId extends TestNumber {
  desk_id: string;
  integration_id: string;
}

export const useWhatsappTesting = (brainId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const canWriteRules = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );

  const canWriteIntegrations = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] =
    useState<TestNumberWithDeskId[]>(undefined);
  const [selectedNumber, setSelectedNumber] =
    useState<TestNumberWithDeskId>(undefined);
  const [creatingNewNumber, setCreatingNewNumber] = useState(false);

  const { brain } = useBrains(brainId);
  const { getAllIntegrations } = useDesks();
  const { integration, updateIntegration, updateStatus, updateError } =
    useIntegrations<WhatsAppIntegration>(
      selectedNumber?.desk_id,
      selectedNumber?.integration_id
    );

  const {
    rule,
    getStatus: ruleGetStatus,
    reorderRule,
  } = useRules(selectedNumber?.desk_id, selectedNumber?.rule_id);

  useEffect(() => {
    getAllIntegrations('whatsapp').then(
      (waIntegrations: WhatsAppIntegration[]) => {
        setPhoneNumbers(
          waIntegrations?.reduce((acc, int) => {
            if (int?.config?.test_numbers) {
              return [
                ...acc,
                ...int.config.test_numbers.map((pn) => ({
                  ...pn,
                  desk_id: int.desk_id,
                  integration_id: int.integration_id,
                })),
              ];
            }

            return acc;
          }, [] as TestNumberWithDeskId[])
        );
      }
    );
  }, [getAllIntegrations, integration]);

  const createAndSelectTestRule = useCallback(
    async (testNumber: TestNumberWithDeskId): Promise<void> => {
      setIsLoading(true);
      try {
        const addPayload = getTestRulePayload(
          t,
          testNumber,
          brainId,
          brain?.name
        );
        const rule = await rulesAPI.addRule(testNumber.desk_id, addPayload);
        onRuleCreated(queryClient, rule, testNumber.desk_id);
        setSelectedNumber({ ...testNumber, rule_id: rule.rule_id });
      } finally {
        setIsLoading(false);
      }
    },
    [brain?.name, brainId, queryClient, t]
  );

  const updateAndSelectTestRule = useCallback(
    async (testNumber: TestNumberWithDeskId) => {
      setIsLoading(true);
      try {
        const existingRule = await rulesAPI.getRule(
          testNumber.desk_id,
          testNumber.rule_id
        );
        const updatePayload = getTestRulePayload(
          t,
          testNumber,
          brainId,
          brain?.name,
          existingRule
        );
        const rule = await rulesAPI.updateRule(
          testNumber.desk_id,
          updatePayload
        );
        onRuleUpdated(queryClient, rule, testNumber.desk_id);
      } finally {
        setIsLoading(false);
        setSelectedNumber(testNumber);
      }
    },
    [brain?.name, brainId, queryClient, t]
  );

  const onNumberCreate = useCallback(
    async (
      testNumber: TestNumberWithDeskId,
      integration: WhatsAppIntegration
    ) => {
      return new Promise<void>((resolve, reject) => {
        const updatePayload = getUpdateIntegrationPayload(
          testNumber,
          integration
        );
        updateIntegration(
          { ...updatePayload, mutationOptions: { hideErrors: true } },
          {
            onSuccess: async () => {
              setSelectedNumber(testNumber);
              setCreatingNewNumber(false);
              resolve();
            },
            onError: () => {
              reject();
            },
          }
        );
      });
    },
    [updateIntegration]
  );

  const onNumberSelect = useCallback(
    async (testNumber: TestNumberWithDeskId) => {
      if (!testNumber.rule_id) {
        await createAndSelectTestRule(testNumber);
        return;
      }

      await updateAndSelectTestRule(testNumber);
    },
    [createAndSelectTestRule, updateAndSelectTestRule]
  );

  useEffect(() => {
    if (
      selectedNumber &&
      (ruleGetStatus === 'error' || !selectedNumber.rule_id)
    ) {
      createAndSelectTestRule(selectedNumber);
    }
  }, [
    canWriteRules,
    createAndSelectTestRule,
    queryClient,
    rule,
    ruleGetStatus,
    selectedNumber,
  ]);

  useEffect(() => {
    if (selectedNumber && rule && rule.position != 1 && canWriteRules) {
      reorderRule({
        rule_id: selectedNumber.rule_id,
        position: 1,
      });
    }
  }, [canWriteRules, reorderRule, rule, selectedNumber]);

  useEffect(() => {
    if (selectedNumber && integration && canWriteIntegrations) {
      const updatePayload = getUpdateIntegrationPayload(
        selectedNumber,
        integration
      );

      if (updatePayload) {
        updateIntegration(updatePayload);
      }
    }
  }, [canWriteIntegrations, integration, selectedNumber, updateIntegration]);

  const modalState: WhatsAppModalState = useMemo(() => {
    if (!phoneNumbers || !brain || isLoading) {
      return 'pending';
    }

    if (!selectedNumber && phoneNumbers.length > 0 && !creatingNewNumber) {
      return 'selecting';
    }

    if ((!selectedNumber && phoneNumbers.length === 0) || creatingNewNumber) {
      return 'creating';
    }

    if (!rule?.rule_id || updateStatus === 'pending') {
      return 'pending';
    }

    return 'testing';
  }, [
    brain,
    creatingNewNumber,
    isLoading,
    phoneNumbers,
    rule?.rule_id,
    selectedNumber,
    updateStatus,
  ]);

  return {
    modalState,
    selectedNumber,
    phoneNumbers,
    brainName: brain?.name,
    hasPermission: canWriteRules && canWriteIntegrations,
    onNumberCreate,
    onNumberCreateError: updateError,
    onNumberSelect,
    onAddNumberClick: () => setCreatingNewNumber(true),
    onBackClick: () => setSelectedNumber(undefined),
  };
};
