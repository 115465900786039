import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkmark from '@/components/atoms/Icons/Checkmark';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { AddGuidelineButton } from './AddGuidelineButton';
import { CharactersLinearProgress } from './CharactersLinearProgress';
import { CustomGuidelineForm } from './CustomGuidelineForm';
import { CustomInstructions } from './CustomInstructions';
import { Objections } from './Objections';
import { SummaryFooter } from './SummaryFooter';
import { useKnowledge } from '../../hooks/useKnowledge';
import { NumberIcon } from '../NumberIcon/NumberIcon';
import { Summary } from '../Summary/Summary';

export const CustomerSupportKnowledge = () => {
  const { t } = useTranslation();
  const { sortedCustomGuidelines, customInstructions } = useKnowledge();
  const initialOrder = customInstructions ? 3 : 2;

  return (
    <>
      <Box pl="var(--space-16)">
        <Typography
          component="h3"
          variant="h3-semi-bold"
          color="var(--text-default-black)"
        >
          {t('ai_agents.knowledge.title_customer_support')}
        </Typography>
        <Typography component="p" color="var(--text-default-gray)">
          {t('ai_agents.knowledge.subtitle_customer_support')}
        </Typography>
      </Box>

      {/* We display the custom_instructions only if it's not empty
       *  This is for backward compatibility in order to not lose the
       *  custom_instructions when we switching to the new UI
       *  If you leave the custom instructions empty, it will disappear
       *  and never be shown again
       */}
      {customInstructions && <CustomInstructions />}

      <Objections order={customInstructions ? 2 : 1} />

      {sortedCustomGuidelines.map((customGuideline, index) => {
        return (
          <CustomGuidelineForm
            key={customGuideline.created_at}
            {...customGuideline}
            order={index + initialOrder}
          />
        );
      })}
    </>
  );
};

export const CustomerSupportSummary = () => {
  const { t } = useTranslation();
  const { sortedCustomGuidelines, customInstructions } = useKnowledge();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { guidelines } = brain || {};
  const { objections } = guidelines || {};
  const initialOrder = customInstructions ? 3 : 2;

  return (
    <>
      <Summary icon={<AddGuidelineButton />} footer={<SummaryFooter />}>
        {customInstructions && (
          <Summary.Step
            onClick={() => scrollToElementById(`ai-agents-custom-instructions`)}
            text={t('ai_agents.knowledge.custom_instructions.title')}
            icon={<Checkmark />}
          />
        )}

        <Summary.Step
          text={t('ai_agents.knowledge.objections.title')}
          onClick={() => scrollToElementById('ai-agent-objections')}
          icon={
            objections?.length > 0 ? (
              <Checkmark />
            ) : (
              <NumberIcon number={customInstructions ? 2 : 1} />
            )
          }
        />

        {sortedCustomGuidelines.map((customGuideline, index) => {
          const order = index + initialOrder;
          const icon = customGuideline.body ? (
            <Checkmark />
          ) : (
            <NumberIcon number={order} />
          );

          return (
            <Summary.Step
              key={customGuideline.created_at}
              onClick={() => scrollToElementById(`custom-guideline-${order}`)}
              text={customGuideline.title}
              icon={icon}
            />
          );
        })}
      </Summary>

      <CharactersLinearProgress />
    </>
  );
};
