import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

export const useKnowledge = () => {
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const {
    guidelines: { custom_instructions = '', custom_guidelines = [] } = {},
  } = brain || {};

  const sortedCustomGuidelines = sortBy(custom_guidelines, ['created_at']);
  const customInstructions = custom_instructions;

  return { sortedCustomGuidelines, customInstructions };
};
