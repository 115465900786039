import { memo } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { AudioIcon } from '@/components/atoms/Icons/AudioIcon';
import BrainIcon from '@/components/atoms/Icons/Robot';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import {
  ConversationSource,
  Message as MessageType,
  User,
} from '@/models/chat';
import { useBroadcasts } from '@/modules/broadcast/hooks/useBroadcasts';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';
import { splitArray } from '@/util/util';

import { Attachment } from './Attachment/Attachment';
import Attachments from './Attachment/Attachments';
import { Response } from './Response/Response';

import styles from './Message.module.scss';

interface Props {
  message: MessageType;
  showAvatar?: boolean;
  user: Partial<User>;
  group: boolean;
  visitorStatus: 'online' | 'offline' | 'away' | null;
  lastVisitorActivity: string | null;
  source: ConversationSource;
}

const Message = ({
  message,
  showAvatar = false,
  user,
  group,
  visitorStatus,
  lastVisitorActivity,
  source,
}: Props) => {
  const { t } = useTranslation();
  const brainId = message.author_type === 'brain' ? message.author_id : null;
  const { brain } = useBrains(brainId);
  const broadcastId =
    message.author_type === 'broadcast' ? message.author_id : null;
  const { broadcast } = useBroadcasts(broadcastId);
  const { ai_agents } = useFeatureFlag();
  // VISITOR
  if (message.author_type === 'visitor') {
    const hasAttachments = message?.body?.attachments?.length > 0;

    return (
      <div className={styles.visitorContainer}>
        {group && (
          <span className={styles.visitorAvatar}>
            <Tooltip
              arrow
              title={t('conversation.last_visitors_activity', {
                activity: lastVisitorActivity,
              })}
            >
              <Avatar
                size="medium"
                src={user?.avatar}
                status={visitorStatus}
                hideTooltip
              />
            </Tooltip>
          </span>
        )}
        <div
          className={cn(styles.visitorMessage, {
            [styles.lastOfGroup]: group,
          })}
        >
          {hasAttachments && (
            <Box mb={message?.text ? 1 : 0}>
              {splitArray(message?.body?.attachments, 4).map(
                (chunk, chunkIndex) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={chunkIndex}
                    className={cn(styles.attachmentContainer, {
                      [styles.hasText]: !!message?.text,
                    })}
                  >
                    <Attachments>
                      {chunk.map((attachment) => (
                        <Attachment
                          key={attachment?.id ?? attachment.url}
                          attachment={attachment}
                          source={source}
                          isSingleItem={message?.body?.attachments.length === 1}
                          authorType="visitor"
                        />
                      ))}
                    </Attachments>
                  </div>
                )
              )}
            </Box>
          )}
          {message.body?.type === 'audio' && message?.text?.length > 0 && (
            <span className={styles.audioTitle}>
              <AudioIcon />
              <Typography
                variant="label-regular"
                color="var(--text-default-gray-light)"
                className={styles.audio}
              >
                {t('conversation.audio_message')}
              </Typography>
            </span>
          )}
          <Typography variant="body-regular" color="var(--text-default-black)">
            {message.text}
          </Typography>
        </div>
      </div>
    );
  }

  // AGENT with attachments
  if (
    message.author_type === 'agent' &&
    message?.body?.attachments?.length > 0
  ) {
    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]:
            message.body?.type === 'text' && message?.body?.options,
        })}
      >
        {showAvatar && (
          <Avatar size="medium" userId={message.author_id} hideStatus />
        )}
        <Response
          text={message.text || ''}
          attachments={message.body.attachments}
          source={source}
          authorType={message.author_type}
        />
      </div>
    );
  }

  // BRAIN
  if (message.author_type === 'brain') {
    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]:
            message.body.type === 'text' && message?.body?.options,
        })}
      >
        {showAvatar && (
          <Tooltip
            arrow
            title={`${t(`common.${ai_agents ? 'aiAgent' : 'brain'}Cap`)} ${
              brain?.name
            }`}
          >
            <BrainIcon size={24} />
          </Tooltip>
        )}
        <Response {...message.body} hasAvatar={showAvatar} />
      </div>
    );
  }

  // BROADCAST
  if (message.author_type === 'broadcast') {
    return (
      <div
        className={cn(styles.container, styles.broadcast, {
          [styles.withAvatar]: showAvatar,
        })}
      >
        {showAvatar && (
          <Tooltip
            arrow
            title={
              broadcast
                ? `${t('main_navigation.broadcast_one')} ${broadcast?.name}`
                : t('broadcasts.deleted_broadcast')
            }
          >
            <IconBroadcast size={24} color="var(--icon-default-gray)" />
          </Tooltip>
        )}
        <Response
          action_id={message.message_id}
          type="text"
          text={message?.text}
          hasAvatar={showAvatar}
          {...message.body}
        />
      </div>
    );
  }

  // TEMPLATES
  if ((message.body?.type as string) === 'template') {
    const options = message?.body?.template?.components
      ?.find((component) => component.type.toLowerCase() === 'buttons')
      ?.buttons?.map((option) => ({
        text: option.text,
        label: option.text,
      }));

    return (
      <div
        className={cn(styles.container, {
          [styles.withAvatar]: showAvatar,
          [styles.textWithOptions]: options,
        })}
      >
        {showAvatar && (
          <Avatar size="medium" userId={message.author_id} hideStatus />
        )}
        <Response
          action_id={message.message_id}
          type="text"
          text={message?.text}
          hasAvatar={showAvatar}
          source={source}
          options={options}
        />
      </div>
    );
  }

  // FALLBACK (includes older agent messages without attachments)
  return (
    <div
      className={cn(styles.container, {
        [styles.withAvatar]: showAvatar,
        [styles.textWithOptions]:
          message.body?.type === 'text' && message?.body?.options,
      })}
    >
      {showAvatar && (
        <Avatar size="medium" userId={message.author_id} hideStatus />
      )}
      {message.body ? (
        <Response
          text={message?.text}
          {...message.body}
          hasAvatar={showAvatar}
          source={source}
        />
      ) : (
        <Response
          action_id={message.message_id}
          type="text"
          text={message?.text}
          hasAvatar={showAvatar}
          source={source}
        />
      )}
    </div>
  );
};

export default memo(Message);
