import { useState, useCallback, useRef, useEffect } from 'react';

interface UseSliderProps {
  value: number;
  onChange: (value: number, options?: { shouldDirty?: boolean }) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
}

const roundToTwoDecimals = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const useSlider = ({
  value,
  onChange,
  min = 0,
  max = 0.99,
  step = 0.05,
  disabled = false,
}: UseSliderProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const trackRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);

  // Calculate the percentage for the progress bar
  const percentage = Math.min(Math.max(value * 100, 0), 100);

  // Generate dots for the meter
  const dots = Array.from({ length: 11 }).map((_, i) => ({
    position: i * 10,
    active: percentage >= i * 10,
  }));

  const updateValue = useCallback(
    (clientX: number) => {
      if (disabled || !trackRef.current) return;

      const rect = trackRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
      const rawValue = x / rect.width;
      // Round to nearest step and cap at max
      const roundedValue = Math.min(Math.round(rawValue / step) * step, max);
      onChange(roundToTwoDecimals(roundedValue), { shouldDirty: true });
    },
    [onChange, step, max, disabled]
  );

  const handleTrackClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (disabled) return;
      updateValue(event.clientX);
    },
    [updateValue, disabled]
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) return;
      event.preventDefault();
      setIsDragging(true);
      updateValue(event.clientX);
    },
    [updateValue, disabled]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (isDragging) {
        updateValue(event.clientX);
      }
    },
    [isDragging, updateValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (disabled) return;
      const currentValue = value || 0;
      if (e.key === 'ArrowLeft') {
        const newValue = Math.max(min, currentValue - step);
        onChange(roundToTwoDecimals(newValue), {
          shouldDirty: true,
        });
      } else if (e.key === 'ArrowRight') {
        const newValue = Math.min(max, currentValue + step);
        onChange(roundToTwoDecimals(newValue), {
          shouldDirty: true,
        });
      }
    },
    [value, onChange, step, min, max, disabled]
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return {
    percentage,
    dots,
    trackRef,
    markerRef,
    handleTrackClick,
    handleMouseDown,
    handleKeyDown,
  };
};
