import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { isKeyEnter } from '@/util/util';

import styles from './Triggers.module.scss';

interface Props {
  operator: string;
  disabled?: boolean;
  outerIndex?: number;
  callbackFn?: (x: { operator: 'and' | 'or'; outerIndex: number }) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isHover?: boolean;
}

const OperatorMenu = ({
  operator,
  disabled = false,
  outerIndex,
  callbackFn,
  onMouseEnter,
  onMouseLeave,
  isHover = false,
}: Props) => {
  const { t } = useTranslation();
  const handleSelect = useCallback(() => {
    callbackFn({ operator: operator === 'or' ? 'and' : 'or', outerIndex });
  }, [callbackFn, operator, outerIndex]);

  const handleKeyStroke = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        handleSelect();
      }
    },
    [handleSelect]
  );

  return (
    <>
      <Typography
        variant="label-semi-bold"
        component="div"
        onClick={handleSelect}
        onKeyDown={handleKeyStroke}
        role="button"
        tabIndex={0}
        className={cn(styles.conjunction, {
          [styles.disabled]: disabled,
          [styles.isHover]: isHover,
        })}
        aria-label="operator"
        aria-controls="operator"
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onMouseEnter}
      >
        {t(`rules.${operator}`)}
      </Typography>
    </>
  );
};

OperatorMenu.displayName = 'OperatorMenu';

export default OperatorMenu;
