import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Fieldset from '@/components/atoms/Fieldset';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Inputs from '@/components/atoms/Inputs/Inputs';
import useUser from '@/hooks/useUser';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { noop } from '@/util/util';
import { emailPattern, isRequired } from '@/util/validator';

import styles from './UsageLimits.module.scss';

type Form = {
  limit: number;
  emails: {
    name: string;
  }[];
};

const defaultValues = {
  emails: [
    {
      name: '',
    },
  ],
};

const checkboxes = ['50%', '75%', '100%'];

const UsageLimitsFieldset = () => {
  const { t } = useTranslation();
  const { customer, updateCustomerStatus } = useBilling();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<Form>({
    defaultValues,
  });

  const { user } = useUser();
  const [percentages, setPercentages] = useState([]);
  const isLoading = customer === undefined;

  const onCheckboxChange = (key) => {
    if (percentages.includes(key)) {
      setPercentages((prev) => prev.filter((v) => v !== key));
    } else {
      setPercentages((prev) => [...prev, key]);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const values = useWatch({
    control,
    name: 'emails',
  });

  const validator = {
    pattern: {
      value: emailPattern,
      message: t('errors.invalid_email'),
    },
  };

  return (
    <FormFieldset
      title={t('billing.usage_limits')}
      subtitle={t('billing.usage_limits_subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateCustomerStatus === 'pending',
      }}
      onSubmit={handleSubmit(noop)}
      isLoading={isLoading}
    >
      <Box mt={5} className={styles.input__wrapper}>
        <Input
          name="limit"
          label={t('billing.usage_limit')}
          register={register('limit', isRequired)}
          errorMessage={errors.limit?.message}
          type="number"
          size="large"
        />
        <Input
          size="large"
          value={t('common.conversations')}
          readOnly
          tabIndex={-1}
        />
      </Box>
      <Box mt={2}>
        <Typography color="var(--text-default-gray)" variant="label-caps-large">
          {t('billing.usage_limit_label')}
        </Typography>
        <div className={styles.checkboxesWrapper}>
          {checkboxes.map((key) => (
            <Fieldset.Checkbox
              key={key}
              label={key}
              onChange={() => onCheckboxChange(key)}
              checked={percentages.includes(key)}
            />
          ))}
        </div>
      </Box>
      <Box mt={4}>
        <Typography
          component="p"
          color="var(--text-default-gray)"
          variant="label-caps-large"
          mb={1}
        >
          {t('billing.emails')}
        </Typography>
        <Inputs
          fields={fields}
          errors={errors.emails}
          register={register}
          values={values}
          onCreate={() => prepend({ name: '' })}
          onDelete={(index) => remove(index)}
          namePrefix="emails"
          validator={validator}
          placeholder={user?.email}
        />
      </Box>
    </FormFieldset>
  );
};

export default UsageLimitsFieldset;
