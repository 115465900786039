import { useState } from 'react';

export const useForceRerender = () => {
  const [editorKey, setEditorKey] = useState(0);

  const forceRerender = () => {
    setEditorKey((prevKey) => prevKey + 1);
  };

  return { editorKey, forceRerender };
};
