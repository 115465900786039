import { memo } from 'react';

import Modal from '@/components/organisms/Modals/Modal';

import ModalCarousel from './ModalCarousel/ModalCarousel';

interface Props {
  cards: {
    title: string;
    body: string;
    img: string;
  }[];
  onSubmit: () => void;
  onBlur: () => void;
}

function ModalShowCase({ cards, onSubmit, onBlur }: Props) {
  return (
    <Modal size="small" autoFocus passiveModal onBlur={onBlur}>
      <ModalCarousel cards={cards} onSubmit={onSubmit} onBlur={onBlur} />
    </Modal>
  );
}

export default memo(ModalShowCase);
