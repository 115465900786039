import { ConfidenceThreshold } from './ConfidenceThreshold/ConfidenceThreshold';
import { InactivityTimeout } from './InactivityTimeout/InactivityTimeout';
import { LanguageModel } from './LanguageModel/LanguageModel';
import { useDirtyFormBlocker } from '../../hooks/useDirtyFormBlocker';

export const AdvancedSettings = () => {
  useDirtyFormBlocker();
  return (
    <>
      <LanguageModel />
      <ConfidenceThreshold />
      <InactivityTimeout />
    </>
  );
};
