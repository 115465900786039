import { useCallback } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TEMPLATE_MESSAGE } from '@/components/organisms/Modals/ModalConductor';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

export const useTemplateMessage = () => {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);

  const dispatch = useDispatch();
  const { conversation } = useConversations({
    deskId,
    sessionId,
  });

  const handleClick = useCallback(() => {
    dispatch(
      pushModal(MODAL_TEMPLATE_MESSAGE, {
        desk_id: conversation?.desk_id,
        integration_id: conversation?.integration_id,
        onPrimaryButtonClick: () => {
          dispatch(popModal());
        },
      })
    );
  }, [conversation?.desk_id, conversation?.integration_id, dispatch]);

  const isExpired = !conversation?.state?.active;
  const isWhatsapp = conversation?.channel === 'whatsapp';

  const isLastMessageAfterOneDay =
    moment().diff(moment.utc(conversation?.last_message_at), 'days') >= 1;

  const showTemplateMessage =
    isLastMessageAfterOneDay && isWhatsapp && !isExpired;

  return {
    showTemplateMessage,
    handleClick,
  };
};
