import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_L } from '@/util/validator';

import { fieldSchema, removeTrailingBRs } from '../../helper';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  goal: string;
};

const FORM_ID = 'ai-agent-goal';

export const GoalForm = ({ number }) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);
  const isDebtCollection = brain?.brain_type === 'debt_collection';

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const {
    formState: { errors, isSubmitting, isDirty },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm<Form>({
    mode: 'onSubmit',
    resolver: yupResolver(
      fieldSchema<Form>('goal', LENGTH_L, t)
    ) as Resolver<Form>,
    values: {
      goal: brain?.guidelines?.goal ?? '',
    },
  });

  const { editorKey, forceRerender } = useForceRerender();

  const onSubmit = ({ goal }: Form) => {
    // Prevent the form from being submitted if the values have not changed
    if (!isDirty) {
      return;
    }

    const cleanedGoal = removeTrailingBRs(goal);

    updateBrain(
      {
        guidelines: {
          ...brain.guidelines,
          goal: cleanedGoal,
        },
        brain_id: brainId,
      },
      {
        onSuccess: () => {
          reset({ goal: cleanedGoal });
          forceRerender();
        },
      }
    );
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.goal.title')}
        subtitle={t('ai_agents.goal.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <MarkdownEditor<Form>
          name="goal"
          setValue={setValue}
          label={t('ai_agents.goal.label')}
          defaultValue={brain?.guidelines?.goal}
          placeholder={t(
            `ai_agents.goal.placeholder${isDebtCollection ? '_debt_collection' : ''}`
          )}
          error={!!errors.goal}
          errorMessage={capitalizeFirstLetter(errors.goal?.message)}
          disabled={!canWrite}
          key={editorKey}
          value={watch('goal')}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
