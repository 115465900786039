import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import uniqBy from 'lodash/uniqBy';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Select from '@/components/atoms/Select/Select';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useTrackFormState } from '@/modules/aiAgents/hooks/useTrackFormState';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { providerNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';

export interface FormValues {
  provider: string;
  language_model_id: string;
}

const FORM_ID = 'ai-agent-language-model';

export const schema = yup.object().shape({
  provider: yup.string().required(),
  language_model_id: yup.string().required(),
});

export const LanguageModel = () => {
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain, getStatus, updateBrain, updateStatus } = useBrains(brainId);
  const { enabledModelsWithMoveo, languageModel } = useLanguageModels(
    brain?.language_model_id
  );

  const uniqueProviders = uniqBy(enabledModelsWithMoveo, 'provider');
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { dirtyFields, errors },
    reset,
  } = useForm<FormValues>({
    values: {
      provider: languageModel?.provider || 'moveo',
      language_model_id: languageModel?.language_model_id || 'Default',
    },
    resolver: yupResolver(schema) as Resolver<FormValues>,
  });

  const isFormDirty = Object.keys(dirtyFields).length > 0;
  useTrackFormState({ isDirty: isFormDirty, formId: FORM_ID });

  useEffect(() => {
    reset({
      provider: languageModel?.provider || 'moveo',
      language_model_id: languageModel?.language_model_id || 'Default',
    });
  }, [languageModel?.language_model_id, languageModel?.provider, reset]);

  const onSubmit = useCallback(
    ({ language_model_id }: FormValues) => {
      updateBrain({
        brain_id: brainId,
        language_model_id:
          language_model_id === 'Default' ? null : language_model_id,
      });
    },
    [brainId, updateBrain]
  );

  const hasSelectedProvider = watch('provider') !== '';

  const options =
    uniqueProviders?.map((model) => ({
      label: providerNameMapper(model.provider as ProviderName),
      value: model.provider,
    })) ?? [];

  return (
    <FormFieldset
      title={t('brains.llms.fieldset.title')}
      subtitle={t('brains.llms.fieldset.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isFormDirty,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={getStatus === 'pending'}
    >
      {options.length === 0 ? (
        <Skeleton variant="rectangular" width="100%" height="84px" />
      ) : (
        <Box mt="var(--space-24)" display="flex" gap="var(--space-16)">
          <Select
            size="full"
            name="provider"
            label={t('field.provider')}
            placeholder={t('common.select_an_option')}
            disabled={!canWrite}
            register={register('provider')}
            error={!!errors.provider}
            errorMessage={errors.provider?.message}
            options={options}
            onChange={(e) => {
              const selected = e.target.value;
              const firstModelOption = uniqueProviders.find(
                (m) => m.provider === selected
              );
              setValue(
                'language_model_id',
                firstModelOption?.language_model_id
              );
            }}
          />

          <Select
            size="full"
            name="language_model_id"
            disabled={!hasSelectedProvider || !canWrite}
            label={t('field.model')}
            placeholder={t('common.select_an_option')}
            register={register('language_model_id')}
            error={!!errors.language_model_id}
            errorMessage={errors.language_model_id?.message}
            options={enabledModelsWithMoveo
              ?.filter((model) => model.provider === watch('provider'))
              ?.map((model) => ({
                label: model.model,
                value: model.language_model_id as string,
              }))}
          />
        </Box>
      )}
    </FormFieldset>
  );
};
