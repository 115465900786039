import { useState, PropsWithChildren, ReactNode } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import { noop } from '@/util/util';

import styles from './CustomDetails.module.scss';

type CustomDetailsProps = {
  title: ReactNode;
  open?: boolean;
  showBorder?: boolean;
  keepOpen?: boolean;
  option?: ReactNode;
};

export const CustomDetails = ({
  children,
  title,
  open = true,
  showBorder = true,
  keepOpen = false,
  option,
}: PropsWithChildren<CustomDetailsProps>) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const Chevron = () => {
    if (keepOpen) {
      return null;
    }
    if (isOpen) {
      return (
        <ExpandLess fontSize="small" htmlColor="var(--icon-default-gray)" />
      );
    } else {
      return (
        <ExpandMore fontSize="small" htmlColor="var(--icon-default-gray)" />
      );
    }
  };

  return (
    <div className={styles.details}>
      <button
        className={classNames(styles.summary, {
          [styles.show_border]: showBorder,
        })}
        onClick={!keepOpen ? toggleOpen : noop}
      >
        <Typography variant="body-semi-bold" color="var(--text-default-gray)">
          {title}
        </Typography>
        <Chevron />
      </button>
      {option && <div className={styles.option}>{option}</div>}
      {isOpen && (
        <div className={styles.content}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
