import { useCallback, useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { SocketContext } from '@/contexts/rtm';
import { SocketEvent } from '@/models/rtm';

import useUser from '../useUser';
import { useRtm } from './useRtm';
import { onViewUpdated } from '../useViews';

export const useRtmViews = () => {
  const { socket } = useContext(SocketContext);
  const { create, remove } = useRtm();
  const { user } = useUser();
  const userId = user?.user_id;
  const queryClient = useQueryClient();

  // create
  const handleCreate = useCallback(
    ({ user_id, data }) => {
      if (user_id !== userId) {
        const { desk_id, view_id } = data;
        create('views', desk_id, view_id);
      }
    },
    [userId, create]
  );
  useEffect(() => {
    socket?.on(SocketEvent.views_created, handleCreate);
    return () => {
      socket?.off(SocketEvent.views_created, handleCreate);
    };
  }, [socket, handleCreate]);

  // update
  const handleUpdate = useCallback(
    ({ user_id, data }) => {
      if (user_id !== userId) {
        // Passing function directly instead of using the generic from useRtm because when receiving this event
        // exists the possibility of not having access anymore to a view, which will throw an error when fetching it.
        onViewUpdated(queryClient, data);
      }
    },
    [userId, queryClient]
  );
  useEffect(() => {
    socket?.on(SocketEvent.views_updated, handleUpdate);
    return () => {
      socket?.off(SocketEvent.views_updated, handleUpdate);
    };
  }, [socket, handleUpdate]);

  // remove
  const handleRemove = useCallback(
    ({ user_id, data }) => {
      if (user_id !== userId) {
        const { desk_id, view_id } = data;
        remove('views', desk_id, view_id);
      }
    },
    [userId, remove]
  );
  useEffect(() => {
    socket?.on(SocketEvent.views_removed, handleRemove);
    return () => {
      socket?.off(SocketEvent.views_removed, handleRemove);
    };
  }, [socket, handleRemove]);
};
