import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { isConditionNested } from '@/components/organisms/Condition/util';
import { Condition, ConditionState } from '@/models/condition';

export const initialState: ConditionState = {
  condition: {
    operator: 'or',
    conditions: [],
  },
};

const slice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    isConditionError: (state: ConditionState, action) => {
      const { index, outerIndex, errorState } = action.payload;
      const isNested = isConditionNested(state.condition);

      if (isNested) {
        if (outerIndex + 1 > state.condition.conditions.length) {
          return state;
        }
        if (
          index + 1 >
          state.condition.conditions[outerIndex].conditions.length
        ) {
          return state;
        }
        state.condition.conditions[outerIndex].conditions[index].isError =
          errorState;
      } else {
        if (index + 1 > state.condition.conditions.length) {
          return;
        } else {
          state.condition.conditions[index].isError = errorState;
        }
      }
    },
    addRuleCondition: (state: ConditionState, action) => {
      const { attribute, comparison, value, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        state.condition.conditions[outerIndex].conditions.push({
          attribute,
          comparison,
          value,
          isConditionOpen: true,
          isError: false,
        });
      } else {
        state.condition.conditions.push({
          attribute,
          comparison,
          value,
          isConditionOpen: true,
          isError: false,
        });
      }
    },
    isConditionOpen: (state: ConditionState, action) => {
      const { index, outerIndex, revertBoolean } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        if (!revertBoolean) {
          state.condition.conditions[outerIndex].conditions[
            index
          ].isConditionOpen = revertBoolean;
        } else {
          state.condition.conditions.forEach((conditionGroup, outerIdx) => {
            if (outerIdx === outerIndex) {
              conditionGroup.conditions.forEach((condition, idx) => {
                if (index === idx) {
                  return (condition.isConditionOpen = revertBoolean);
                }
                return (condition.isConditionOpen = !revertBoolean);
              });
            } else {
              conditionGroup.conditions.forEach(
                (condition) => (condition.isConditionOpen = !revertBoolean)
              );
            }
          });
        }
      } else {
        if (!revertBoolean) {
          state.condition.conditions[index].isConditionOpen = revertBoolean;
        } else {
          state.condition.conditions.forEach((condition, idx) => {
            if (index === idx) {
              return (condition.isConditionOpen = revertBoolean);
            }
            return (condition.isConditionOpen = !revertBoolean);
          });
        }
      }
    },

    replaceRuleCondition: (state: ConditionState, action) => {
      const { attribute, comparison, value, index, outerIndex } =
        action.payload;

      const isNested = isConditionNested(state.condition);
      const newCondition = {
        attribute,
        comparison,
        value: value ? value : null,
        isError: false,
      };

      if (isNested) {
        state.condition.conditions[outerIndex].conditions[index] = newCondition;
      } else {
        state.condition.conditions[index] = newCondition;
      }
    },
    deleteRuleCondition: (state: ConditionState, action) => {
      const { index, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);

      if (isNested) {
        if (
          state.condition.conditions.length === 2 &&
          index === 0 &&
          state.condition.conditions[outerIndex].conditions.length === 1
        ) {
          const operator =
            state.condition.conditions[outerIndex === 0 ? 1 : 0].operator;
          const conditions = [
            ...state.condition.conditions[outerIndex === 0 ? 1 : 0].conditions,
          ];
          state.condition.conditions = [];
          state.condition.conditions = conditions;
          state.condition.operator = operator;
          return;
        }
        if (
          index === 0 &&
          state.condition.conditions[outerIndex].conditions.length === 1
        ) {
          state.condition.conditions.splice(outerIndex, 1);
          return;
        }
        state.condition.conditions[outerIndex].conditions.splice(index, 1);
      } else {
        state.condition.conditions.splice(index, 1);
      }
    },
    changeConditionOperator: (state: ConditionState, action) => {
      const { operator, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        state.condition.conditions[outerIndex].operator = operator;
      } else {
        state.condition.operator = operator;
      }
    },
    changeOuterConditionOperator: (state: ConditionState, action) => {
      const { operator } = action.payload;
      state.condition.operator = operator;
    },
    changeOuterOperatorHover: (state: ConditionState, action) => {
      const { isHover } = action.payload;
      state.condition.isHover = isHover;
    },
    addRuleGroup: (state, action) => {
      const { attribute, comparison, value, outerIndex } = action.payload;
      const isNested = isConditionNested(state.condition);
      if (isNested) {
        state.condition.conditions[outerIndex + 1] = {
          operator: 'or',
          conditions: [
            {
              attribute,
              comparison,
              value,
              isConditionOpen: true,
              isError: false,
            },
          ],
        };
      } else {
        if (state.condition.conditions.length === 0) {
          state.condition.conditions[0] = {
            attribute,
            comparison,
            value,
            isConditionOpen: true,
            isError: false,
          };
        } else {
          const condition = { ...state.condition };
          state.condition.conditions = [];
          state.condition.conditions[0] = condition;
          state.condition.conditions[1] = {
            operator: 'or',
            conditions: [
              {
                attribute,
                comparison,
                value,
                isConditionOpen: true,
                isError: false,
              },
            ],
          };
        }
      }
    },
    clearCondition: (state) => {
      Object.assign(state, initialState);
    },
    setCondition: (state, action: PayloadAction<Condition>) => {
      state.condition = action.payload;
    },
  },
});

export const {
  changeOuterOperatorHover,
  changeOuterConditionOperator,
  changeConditionOperator,
  clearCondition,
  deleteRuleCondition,
  replaceRuleCondition,
  isConditionError,
  isConditionOpen,
  addRuleCondition,
  addRuleGroup,
  setCondition,
} = slice.actions;

export default slice.reducer;
