import { useCallback, useState } from 'react';

import Popper from '@mui/material/Popper';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import merge from 'lodash/merge';

const TOOLTIP_STYLE = {
  sx: {
    zIndex: 10,

    '& .MuiTooltip-tooltip': {
      backgroundColor: 'var(--surface-primary-white)',
      border: '0.5px solid var(--border-default-gray)',
      boxShadow: '0 var(--space-4) var(--space-8) 0 rgba(0, 0, 0, 0.08)',
      width: '252px',
      padding: 'var(--space-8)',
    },
  },
};

export const HoverMenu = ({
  children,
  title,
  PopperProps,
  ...props
}: TooltipProps) => {
  const style = merge({}, TOOLTIP_STYLE, PopperProps);

  const [open, setOpen] = useState(false);

  const handleSetOpen = useCallback(() => {
    setTimeout(() => setOpen((prev) => !prev), 100);
  }, []);

  const CustomPopper = useCallback(
    (props) => {
      return (
        <Popper
          {...props}
          onMouseEnter={handleSetOpen}
          onMouseLeave={handleSetOpen}
        />
      );
    },
    [handleSetOpen]
  );

  return (
    <Tooltip
      {...props}
      open={open}
      slots={{ popper: CustomPopper }}
      title={title}
      slotProps={{ popper: style }}
    >
      <div onMouseEnter={handleSetOpen} onMouseLeave={handleSetOpen}>
        {children}
      </div>
    </Tooltip>
  );
};
