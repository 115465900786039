import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Link from '@/components/atoms/Link/Link';
import {
  MODAL_DELETE,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { RootState } from '@/models/state';
import { AI_AGENT_REMINDER_FORM_ID } from '@/modules/aiAgents/components/Setup/Reminder';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { selectSelectedNode } from '@/redux/dialogs/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeNode, removeRequisite } from '@/redux/nodes/actions';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

import useBrains from './useBrains';
import useNodesConnected from './useNodesConnected';

const useMenu = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { findUsedNodes } = useNodesConnected();
  const selectedReqisiteIndex = useSelector(
    (state: RootState) => state.nodes.selectedRequisiteIndex,
    shallowEqual
  );
  const dialogErrors = useSelector(selectDialogAlerts);

  const selectedNodeId = useSelector(
    (state: RootState) => state.nodes.selectedNodeId,
    shallowEqual
  );

  const slug = useSelector(selectAccountSlug);
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const navigate = useNavigate();

  const selectedNode = useSelector(selectSelectedNode);

  const reminders = brain?.guidelines?.reminders || [];
  const numberedReminders = reminders
    .map((reminder, index) => ({
      ...reminder,
      number: index + 1,
    }))
    .filter((reminder) => reminder.node_id === selectedNodeId);
  const isNodeWithReminders = numberedReminders.length > 0;

  const deleteRequisite = useCallback(() => {
    const deleteProps = {
      subtitle: <Trans i18nKey="dialog.delete_action" values={['question']} />,
      confirm: false,
      onDelete: () => {
        dispatch(
          removeRequisite({
            nodeId: selectedNodeId,
            index: selectedReqisiteIndex,
          })
        );

        if (dialogErrors.length) {
          dispatch(
            clearDialogAlerts({
              nodeId: selectedNodeId,
              index: selectedReqisiteIndex,
            })
          );
        }

        dispatch(popModal());
      },
      secondaryButtonText: t('common.cancel'),
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [dialogErrors.length, dispatch, selectedNodeId, selectedReqisiteIndex, t]);

  const deleteNode = useCallback(
    (nodeId: string, name: string, onRefClick) => {
      const usedNodes = findUsedNodes(nodeId);
      if (usedNodes && !isEmpty(usedNodes)) {
        const warning = (
          <Banner
            relativePosition
            variant="critical"
            references={usedNodes}
            onRefClick={onRefClick}
          >
            <Trans i18nKey="dialog.delete_node" values={[name]} />
          </Banner>
        );
        const warnProps = {
          title: t('common.warning'),
          children: warning,
          primaryButtonText: t('common.close'),
        };

        dispatch(pushModal(MODAL_WARN, warnProps));
      } else if (isNodeWithReminders) {
        const warning = (
          <Banner relativePosition variant="critical">
            <Trans
              i18nKey="dialog.delete_node_with_reminders"
              values={[selectedNode?.name]}
              components={{
                link1: (
                  <Link
                    onClick={() => {
                      dispatch(popModal());
                      navigate(
                        `/${slug}/ai-agents/${brainId}/setup#${AI_AGENT_REMINDER_FORM_ID}`
                      );
                    }}
                  />
                ),
              }}
            />
          </Banner>
        );

        const warnProps = {
          title: t('common.warning'),
          children: warning,
          primaryButtonText: t('common.close'),
        };

        dispatch(pushModal(MODAL_WARN, warnProps));
      } else {
        const deleteProps = {
          title: t('dialog.delete_node_title'),
          subtitle: <Trans i18nKey="dialog.delete_node_warn" values={[name]} />,
          onDelete: () => {
            dispatch(removeNode({ nodeId }));

            if (dialogErrors.length) {
              // Clears its errors
              dispatch(
                clearDialogAlerts({
                  id: nodeId,
                })
              );
              // Clears its children errors
              dispatch(
                clearDialogAlerts({
                  nodeId,
                })
              );
            }

            dispatch(popModal());
          },
          secondaryButtonText: t('common.cancel'),
        };

        dispatch(pushModal(MODAL_DELETE, deleteProps));
      }
    },
    [
      brainId,
      dialogErrors.length,
      dispatch,
      findUsedNodes,
      isNodeWithReminders,
      navigate,
      selectedNode?.name,
      slug,
      t,
    ]
  );

  return {
    deleteNode,
    deleteRequisite,
  };
};

export default useMenu;
