import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Clock3 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Chip from '@/components/atoms/Chip/Chip';
import useBrains from '@/hooks/useBrains';
import { selectSelectedNode } from '@/redux/dialogs/selectors';
import { selectBrainId } from '@/redux/session/selectors';

export const AssignedReminders = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const selectedNode = useSelector(selectSelectedNode);

  // Custom hooks
  const { t } = useTranslation();
  const { brain } = useBrains(brainId);

  // Local variables
  const nodeId = selectedNode?.node_id;
  const reminders = brain?.guidelines?.reminders || [];
  const numberedReminders = reminders
    .map((reminder, index) => ({
      ...reminder,
      number: index + 1,
    }))
    .filter((reminder) => reminder.node_id === nodeId);
  const isNodeWithReminders = numberedReminders.length > 0;

  if (!isNodeWithReminders) {
    return null;
  }

  return (
    <Box
      display="flex"
      gap="var(--space-8)"
      alignItems="center"
      flexWrap="wrap"
    >
      <Clock3 size="16" />
      <Typography>{t('conversation.assigned_to')}</Typography>

      {numberedReminders.map((reminder) => (
        <Chip
          key={reminder.number}
          customVariant="highlighted"
          size="medium"
          label={`${t('ai_agents.reminder.timer')} ${reminder.number}`}
        />
      ))}
    </Box>
  );
};
