import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Condition } from '@/models/node';
import { MAX_EXPANDED_CONDITIONS } from '@/util/constants';

const useConditionsCollapsed = (
  nodeId: string,
  conditions: Condition[] | undefined = []
) => {
  const location = useLocation();
  const isDialogPreview = location.pathname.endsWith('/setup');
  const [collapsed, setCollapsed] = useState(!isDialogPreview);
  const [activeConditionIndex, setActiveConditionIndex] = useState(0);

  // Reset collapsed state when nodeId changes
  useEffect(() => {
    // Always expand conditions in dialog preview
    if (isDialogPreview) {
      return;
    }
    const initialCollapsedState = conditions.length > MAX_EXPANDED_CONDITIONS;
    setCollapsed(initialCollapsedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  return {
    collapsed,
    setCollapsed,
    activeConditionIndex,
    setActiveConditionIndex,
  };
};

export default useConditionsCollapsed;
