import { useCallback, useMemo } from 'react';

import { MutateOptions } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';

import { Conversation } from '@/models/chat';
import { ContextUser } from '@/models/user';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { popModal } from '@/redux/modals/actions';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

type UpdateConversationOptions = MutateOptions<
  Conversation,
  Error,
  Partial<Conversation>,
  unknown
>;

export const useConversationContext = () => {
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const { getStatus, context, updateConversation } = useConversations({
    deskId,
    sessionId,
  });

  const tags = useMemo(() => context?.tags || [], [context?.tags]);
  const user = useMemo(() => context?.user, [context?.user]);
  const contextVars = useMemo(() => omit(context, 'user', 'tags'), [context]);
  const filteredContextVars = useMemo(
    () => omit(contextVars, ['global.bundle']),
    [contextVars]
  );

  const addTag = useCallback(
    (tag: string, options?: UpdateConversationOptions) => {
      updateConversation(
        {
          context: {
            tags: [...tags, tag],
          },
        },
        options
      );
    },
    [updateConversation, tags]
  );

  const removeTag = useCallback(
    (tag: string, options?: UpdateConversationOptions) => {
      updateConversation(
        {
          context: {
            tags: tags.filter((t) => t !== tag),
          },
        },
        options
      );
    },
    [updateConversation, tags]
  );

  const updateUser = useCallback(
    (user: Partial<ContextUser>, options?: UpdateConversationOptions) => {
      updateConversation(
        {
          context: {
            user,
          },
        },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
          ...options,
        }
      );
    },
    [dispatch, updateConversation]
  );

  return {
    contextVars,
    filteredContextVars,
    isLoading: getStatus === 'pending',
    user,
    updateUser,
    addTag,
    removeTag,
    tags,
  };
};
