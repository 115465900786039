import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const ScrollToHashElement = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) return;

    const element = document.getElementById(hash.slice(1));

    if (element) {
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      element.classList.add('highlight');

      const timer = setTimeout(() => {
        element.classList.remove('highlight');
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [hash]);

  return null;
};
