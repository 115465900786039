import { useEffect } from 'react';

import {
  useMutation,
  useInfiniteQuery,
  QueryClient,
  useQueryClient,
  InfiniteData,
} from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { useDispatch, useSelector } from 'react-redux';

import {
  messagesEndpoints as endpoints,
  conversationsEndpoints,
} from '@/api/endpoints';
import { callGet, callPost } from '@/api/fetcher';
import { Messages, Message } from '@/models/chat';
import { NewMessage } from '@/models/rtm';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

export type UpdateProps = {
  queryClient: QueryClient;
  message: Message;
  deskId: string;
  delivered_at?: string;
  read_at?: string;
};

export type UploadUrl = {
  upload_url: string;
  session_id: string;
  file_name: string;
  file_id: string;
};

const API = Object.freeze({
  listMessages: async (
    deskId: string,
    sessionId: string,
    cursor?: { cursor: string }
  ): Promise<Messages> =>
    callGet(endpoints.messages(deskId, sessionId, cursor.cursor)),

  createMessage: async ({ deskId, sessionId, ...message }): Promise<Message> =>
    callPost(endpoints.message(deskId, sessionId), message),

  createPresignedUrl: async ({
    deskId,
    sessionId,
    slug,
    ...rest
  }): Promise<UploadUrl> =>
    callPost(endpoints.uploadUrl(deskId, sessionId, slug), rest),
});

export const onMessageCreated = (
  queryClient: QueryClient,
  deskId: string,
  data: NewMessage
) => {
  queryClient.setQueryData<InfiniteData<Messages>>(
    [endpoints.messages(deskId, data.session_id)],
    (p) => {
      if (!p) {
        return p;
      }
      const prev = cloneDeep(p);
      let updated = false;

      for (const page of prev.pages) {
        for (const m of page.messages) {
          if (m.message_id === data.message_id) {
            merge(m, data);
            updated = true;
            break;
          }
        }
        if (updated) break;
      }

      if (!updated) {
        prev.pages?.[0].messages.unshift(data);
      }

      return { pages: prev.pages, pageParams: prev.pageParams };
    }
  );
};

export const onMessageUpdated = (
  queryClient: QueryClient,
  deskId: string,
  sessionId: string
) => {
  queryClient.invalidateQueries({
    queryKey: [conversationsEndpoints.conversation(deskId, sessionId)],
  });
  queryClient.invalidateQueries({
    queryKey: [endpoints.messages(deskId, sessionId)],
  });
};

const useMessages = (deskId: string, sessionId?: string) => {
  const dispatch = useDispatch();
  const slug = useSelector(selectAccountSlug);
  const queryClient = useQueryClient();
  const {
    data: messages,
    status: listStatus,
    fetchNextPage,
    hasNextPage,
    isSuccess: messagesSuccess,
  } = useInfiniteQuery({
    queryKey: [endpoints.messages(deskId, sessionId)],
    queryFn: async ({ pageParam }) => {
      return API.listMessages(deskId, sessionId, pageParam);
    },
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: Boolean(deskId && sessionId),
  });

  const createPresignedUrl = async ({ file_id, file_type, original_name }) => {
    return API.createPresignedUrl({
      deskId,
      sessionId,
      slug,
      file_id,
      file_type,
      original_name,
    });
  };

  useEffect(() => {
    if (messagesSuccess) {
      queryClient.invalidateQueries({
        queryKey: [conversationsEndpoints.conversation(deskId, sessionId)],
      });
    }
  }, [messagesSuccess, messages, queryClient, deskId, sessionId]);
  const { mutate: createMessage, status: createStatus } = useMutation<
    Message,
    Error,
    Partial<Message>
  >({
    mutationFn: (message) => {
      const { text, body } = message;
      return API.createMessage({
        deskId,
        sessionId,
        text,
        body,
        timestamp: Date.now(),
      });
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    messages,
    listStatus,
    createMessage,
    createStatus,
    fetchNextPage,
    hasNextPage,
    createPresignedUrl,
  };
};

export default useMessages;
