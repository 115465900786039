import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { accountsEndpoints } from '@/api/endpoints';
import { callGet, callPut } from '@/api/fetcher';
import { Features } from '@/models/account';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

const endpoint = `/www/api/v1/account/features`;

type UpdateProps = {
  ai_agents: boolean;
};

type APIFeatures = {
  features: Features;
};
export const API = Object.freeze({
  listfeatures: async (): Promise<APIFeatures> => callGet(endpoint),

  updateFeature: async (data: UpdateProps): Promise<APIFeatures> =>
    callPut(endpoint, data),
});

const useFeatureFlag = () => {
  const accountSlug = useSelector(selectAccountSlug);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: launchdarklyFeatures } = useQuery({
    queryKey: [endpoint, accountSlug],
    queryFn: () => API.listfeatures(),
    enabled: !!accountSlug,
  });

  const { mutate: updateFeature } = useMutation<
    APIFeatures,
    Error,
    UpdateProps
  >({
    mutationFn: API.updateFeature,
    onSuccess: (resp) => {
      queryClient.invalidateQueries({
        queryKey: [accountsEndpoints.account, accountSlug],
      });
      const queryKey = [endpoint, accountSlug];
      if (queryClient.getQueryData(queryKey)) {
        queryClient.setQueryData<APIFeatures>(
          queryKey,
          (prev: APIFeatures) => ({
            features: {
              ...(prev?.features || {}),
              ...(resp?.features || {}),
            },
          })
        );
      }
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    ...(launchdarklyFeatures?.features || {}),
    updateFeature,
  };
};

export default useFeatureFlag;
