import { Icon } from '@/models/icon';

export const PrivateView = ({
  color = 'var(--icon-default-gray)',
  size = 16,
}: Icon) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.37 2.16a.5.5 0 1 0-.74.68l1.2 1.32A9 9 0 0 0 .54 7.8a.5.5 0 0 0 0 .4c.02.05.55 1.23 1.73 2.4A7.96 7.96 0 0 0 8 13c1.12 0 2.23-.22 3.25-.68l1.38 1.52a.5.5 0 1 0 .74-.68l-10-11ZM6.33 6.9l2.6 2.87a2 2 0 0 1-2.6-2.87ZM8 12c-1.92 0-3.6-.7-5-2.08A8.32 8.32 0 0 1 1.56 8a8.1 8.1 0 0 1 2.96-3.09l1.13 1.24a3 3 0 0 0 3.98 4.37l.92 1.02A7 7 0 0 1 8 12Zm.37-5.96a.5.5 0 0 1 .2-.99 3.01 3.01 0 0 1 2.42 2.67.5.5 0 0 1-.84.41.5.5 0 0 1-.16-.32 2 2 0 0 0-1.62-1.77Zm7.09 2.16a8.97 8.97 0 0 1-2.09 2.74.5.5 0 1 1-.67-.74A8.3 8.3 0 0 0 14.44 8 8.32 8.32 0 0 0 13 6.08 6.89 6.89 0 0 0 6.79 4.1a.5.5 0 1 1-.17-.99 7.95 7.95 0 0 1 7.1 2.28 8.84 8.84 0 0 1 1.74 2.4.5.5 0 0 1 0 .41Z"
      fill={color}
    />
  </svg>
);
