/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';

import { isKeyEnter, preventClickThrough } from '@/util/util';

import styles from './RuleActionsModal.module.scss';

interface Props {
  children?: ReactNode | string;
  handleSubmit: () => void;
  callbackFn: () => void;
  renderOverElement?: boolean;
  sideComponent?: ReactNode | string;
}

const RuleActionsModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      handleSubmit,
      callbackFn,
      renderOverElement = false,
      sideComponent,
    },
    ref
  ) => {
    const [modalHeight, setModalHeight] = useState<number | null>(null);

    useEffect(() => {
      // We don't want to calculate height when the modal should render below the element
      if (!renderOverElement) {
        return;
      }
      // Check if current exists in ref, t
      if (ref && 'current' in ref && ref.current) {
        const observer = new ResizeObserver(([entry]) => {
          setTimeout(() => {
            setModalHeight(entry.contentRect.height);
          }, 0);
        });

        observer.observe(ref.current);

        return () => observer.disconnect();
      }
    }, [ref, renderOverElement]);

    const handleKeyPress = useCallback(
      (e) => {
        if (e.key === 'Escape') {
          preventClickThrough(e);
          callbackFn();
        }

        if (isKeyEnter(e)) {
          preventClickThrough(e);
          handleSubmit();
        }
      },
      [callbackFn, handleSubmit]
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [handleKeyPress]);

    return (
      <div
        className={cn(styles.wrapper, {
          [styles.renderOverElement]: renderOverElement,
          [styles.isInvisible]: renderOverElement && !modalHeight,
          [styles.withSideComponent]: !!sideComponent,
        })}
        ref={ref}
        // Assigning inline styling because the height is calculated dynamically
        style={renderOverElement ? { top: -modalHeight } : undefined}
      >
        <div className={styles.children}>{children}</div>
        {sideComponent && (
          <div className={styles.children}>{sideComponent}</div>
        )}
      </div>
    );
  }
);

RuleActionsModal.displayName = 'RuleActionsModal';

export default RuleActionsModal;
