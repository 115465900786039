import Box from '@mui/material/Box/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Info from '@/components/atoms/Icons/Info';
import Link from '@/components/atoms/Link/Link';
import useMessages from '@/hooks/useMessages';
import { Message } from '@/models/chat';
import { WHATSAPP_MANAGER_URL } from '@/modules/broadcast/constants';
import { useBroadcasts } from '@/modules/broadcast/hooks/useBroadcasts';
import { BroadcastAction } from '@/modules/broadcast/models';
import {
  filterMessageWhatsappTemplate,
  formatComponentsFromForm,
  getComponentsFromTemplate,
  getVariablesLength,
  startVariablesFromIndex,
} from '@/modules/broadcast/utils';
import { selectSessionId } from '@/redux/session/selectors';
import { emojiFlag } from '@/util/util';

import Modal from '../Modal';
import { TemplateVariables } from './TemplateVariables';

import styles from './ModalTemplateMessage.module.scss';

interface Props {
  integration_id: string;
  desk_id: string;
  onPrimaryButtonClick: () => void;
}

type Form = {
  template: Option;
  headerParameters: { name: string | null }[];
  bodyParameters: { name: string | null }[];
};

export const ModalTemplateMessage = ({
  desk_id,
  integration_id,
  onPrimaryButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const sessionId = useSelector(selectSessionId);

  const { createMessage, createStatus } = useMessages(desk_id, sessionId);
  const formMethods = useForm<Form>({
    defaultValues: {
      template: null,
    },
  });
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = formMethods;

  const templateId = watch('template')?.value;

  const { append: headerAppend } = useFieldArray({
    control,
    name: 'headerParameters',
  });

  const { append: bodyAppend } = useFieldArray({
    control,
    name: 'bodyParameters',
  });

  const { whatsAppTemplates, listwhatsAppTemplatesStatus } = useBroadcasts(
    undefined,
    undefined,
    desk_id,
    integration_id
  );

  const onSubmit = (formData) => {
    const template = whatsAppTemplates?.find((t) => t.id === templateId);

    const components = formatComponentsFromForm(template, formData);

    const message = {
      body: {
        text: template.name,
        template: {
          ...template,
          components,
        } as BroadcastAction,
      },
    } as unknown as Message;

    createMessage(message, {
      onSuccess: () => {
        onPrimaryButtonClick();
      },
    });
  };

  const template = whatsAppTemplates?.find((t) => t.id === templateId);
  const { header, body, footer, buttons } = getComponentsFromTemplate(template);

  const handleTemplateChange = (
    _,
    data: {
      value: string;
    }
  ) => {
    if (data.value !== templateId) {
      setValue('headerParameters', []);
      setValue('bodyParameters', []);
      clearErrors('headerParameters');
      clearErrors('bodyParameters');

      const { header, body } = getComponentsFromTemplate(template);
      const headerVariablesCount = getVariablesLength(header);
      const bodyVariablesCount = getVariablesLength(body);

      for (let i = 0; i < headerVariablesCount; i++) {
        headerAppend({ name: null });
      }
      for (let i = 0; i < bodyVariablesCount; i++) {
        bodyAppend({ name: null });
      }
    }
  };

  const headerVariablesCount = getVariablesLength(header);
  const bodyVariablesCount = getVariablesLength(body);

  const options =
    filterMessageWhatsappTemplate(whatsAppTemplates)?.map((option) => ({
      label: `${emojiFlag(option.language)}   ${option.name}`,
      value: option.id,
      type: option.language,
      node_type: option.language,
    })) || [];

  const variableCount = headerVariablesCount + bodyVariablesCount;
  return (
    <Modal
      primaryButtonDisable={!isValid}
      isSubmitting={createStatus === 'pending'}
      title={t('broadcasts.templates')}
      size="medium"
      primaryButtonText={t('common.send')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      footer={
        <Typography
          marginRight="auto"
          color="var(--text-default-gray)"
          gap="var(--space-8)"
          display="flex"
          alignItems="center"
        >
          <Info backgroundColor="var(--icon-default-gray)" />
          {t('chatBox.incur_charges')}
        </Typography>
      }
    >
      <Typography mb={3} component="p">
        <Trans
          i18nKey="broadcasts.select_template"
          components={{
            link1: <Link href={WHATSAPP_MANAGER_URL} external />,
          }}
        />
      </Typography>
      {listwhatsAppTemplatesStatus === 'pending' ? (
        <Skeleton animation="wave" height={50} />
      ) : (
        <AutocompleteNew
          width={403}
          name="template"
          label={t('broadcasts.templates')}
          control={control}
          options={options}
          placeholder={t('brains.select_a_template')}
          size="medium"
          onChange={handleTemplateChange}
          rules={{
            required: true,
          }}
          disabled={!options || options.length === 0}
        />
      )}
      {templateId && (
        <Box className={styles.templateContainer}>
          {header?.text && (
            <div>
              <Typography variant="body-semi-bold">
                #{t('broadcasts.template_header')}#
              </Typography>
              <Typography component="p">{header.text}</Typography>
            </div>
          )}
          {body?.text && (
            <div>
              <Typography variant="body-semi-bold">
                #{t('dialog.email.body')}#
              </Typography>
              <Typography component="p">
                {startVariablesFromIndex(headerVariablesCount, body.text)}
              </Typography>
            </div>
          )}
          {footer?.text && (
            <div>
              <Typography variant="body-semi-bold">
                #{t('broadcasts.footer')}#
              </Typography>
              <Typography component="p">{footer.text}</Typography>
            </div>
          )}

          {buttons && (
            <div>
              <Typography variant="body-semi-bold">
                #{t('dialog.carousel.buttons')}#
              </Typography>
              <Typography component="p">
                {buttons.map((button, index) => `[${index}] ${button.text} `)}
              </Typography>
            </div>
          )}
        </Box>
      )}
      {variableCount > 0 && templateId && (
        <TemplateVariables<Form>
          key={templateId}
          headerVariablesCount={headerVariablesCount}
          bodyVariablesCount={bodyVariablesCount}
          register={register}
          errors={errors}
        />
      )}
    </Modal>
  );
};
