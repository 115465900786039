import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import AttachmentIcon from '@/components/atoms/Icons/Attachment';
import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { CarouselCard } from '@/models/action';
import {
  ConversationSource,
  Attachment as AttachmentType,
  AuthorType,
} from '@/models/chat';
import { splitArray } from '@/util/util';

import { Attachment } from '../Attachment/Attachment';
import Attachments from '../Attachment/Attachments';

import styles from './Response.module.scss';

interface ExtendedProps {
  type?: string;
  text?: string;
  url?: string;
  id?: string;
  name?: string;
  action_id?: string;
  cards?: CarouselCard[];
  label?: string;
  options?: { text: string; label: string }[];
  attachments?: AttachmentType[];
  hasAvatar?: boolean;
  source?: ConversationSource;
  authorType?: `${AuthorType}`;
}

export const Response = (props: ExtendedProps) => {
  const { html } = useMarkdownToHtml(props.text || '');
  if (props.attachments?.length > 0) {
    return (
      <div
        className={cn(styles.bubble, styles.brain, styles.text, {
          [styles.hasAvatar]: props.hasAvatar,
        })}
      >
        <Box mb={props?.text ? 1 : 0}>
          {splitArray(props.attachments, 4).map((chunk, chunkIndex) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={chunkIndex}
              className={cn(styles.attachmentContainer, {
                [styles.hasText]: !!props?.text,
              })}
            >
              <Attachments>
                {chunk.map((attachment) => (
                  <Attachment
                    key={attachment?.id ?? attachment.url}
                    attachment={attachment}
                    source={props.source}
                    isSingleItem={props.attachments.length === 1}
                    authorType={props.authorType}
                  />
                ))}
              </Attachments>
            </div>
          ))}
        </Box>
        <Typography
          variant="body-regular"
          color="var(--text-default-black)"
          className={styles.aligntRight}
        >
          {props.text}
        </Typography>
      </div>
    );
  }

  switch (props.type) {
    default:
    case 'disambiguation':
      return (
        <>
          <span
            className={cn(styles.bubble, styles.brain, styles.text, {
              [styles.hasAvatar]: props.hasAvatar,
            })}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {props?.options && (
            <div className={styles.optionsContainer}>
              {props.options.map((option) => (
                <button
                  key={`option-${option.text}`}
                  className={cn(styles.bubble, styles.option)}
                  onClick={() => {}}
                  type="button"
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.url)}>
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            {props.url}
          </a>
        </p>
      );
    case 'audio':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          <audio src={props.url} controls />
        </p>
      );
    case 'image':
      return (
        <span className={cn(styles.bubble, styles.brain, styles.image)}>
          {props.id ? (
            <Attachment
              source={props.source}
              attachment={{
                id: props.id,
                type: 'image',
                mime_type: 'image/jpeg',
              }}
              authorType={props.authorType}
            />
          ) : (
            <img
              src={props.url}
              alt={props.name}
              referrerPolicy="no-referrer"
            />
          )}
        </span>
      );
    case 'video':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
          {props.id ? (
            <Attachment
              source={props.source}
              attachment={{
                id: props.id,
                type: 'video',
                mime_type: 'video/mp4',
              }}
              authorType={props.authorType}
            />
          ) : (
            <video src={props.url} controls />
          )}
        </p>
      );
    case 'file':
      return (
        <p className={cn(styles.bubble, styles.brain, styles.file)}>
          {props.id ? (
            <Attachment
              source={props.source}
              attachment={{
                id: props.id,
                type: 'file',
                mime_type: '',
              }}
              authorType={props.authorType}
            />
          ) : (
            <a href={props.url} target="_blank" rel="noopener noreferrer">
              <AttachmentIcon color="var(--icon-default-blue)" />
              {props.name ? props.name : props.text}
            </a>
          )}
        </p>
      );
    case 'carousel':
      return (
        <div
          className={cn(styles.carouselContainer, styles.isChat, {
            [styles.oneCard]: props.cards.length === 1,
          })}
        >
          <CarouselOld isChat cards={props.cards} slidesPerView={2} />
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div className={styles.webviewContainer}>
          <p>{props.name}</p>
          <div className={cn(styles.webviewButtonContainer, styles.disabled)}>
            <p>{props.label}</p>
          </div>
        </div>
      );
  }
};
