import { useCallback, useEffect } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'react-use';

import { MODAL_SHOWCASE } from '@/components/organisms/Modals/ModalConductor';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getRoles } from '@/util/util';

import { useAccount } from './useAccount';
import useFeatureFlag from './useFeatureFlag';
import useMembers from './useMembers';

const newCards = (t: TFunction) => [
  {
    title: t('account.featureRelease1.title'),
    body: t('account.featureRelease1.subtitle'),
    img: '/assets/aiAgents/featureRelease1.png',
  },
  {
    title: t('account.featureRelease2.title'),
    body: t('account.featureRelease2.subtitle'),
    img: '/assets/aiAgents/featureRelease2.png',
  },
  {
    title: t('account.featureRelease3.title'),
    body: t('account.featureRelease3.subtitle'),
    img: '/assets/aiAgents/featureRelease3.png',
  },
];

export const useFeatureUpgrade = () => {
  const { member } = useMembers();
  const role_ids = member?.role_ids;
  const dispatch = useDispatch();
  const { account, accountRole } = useAccount();
  const { isAdminOrOwner } = getRoles(role_ids, accountRole);
  const { ai_agents, ai_agents_upgrade_available, updateFeature } =
    useFeatureFlag();
  const { t } = useTranslation();

  const localStorageKey = `hide_ai_agents_upgrade_${account?.account_id}`;

  // Keep useLocalStorage in sync
  const [, setLocalStorageDismiss] = useLocalStorage(localStorageKey, null);

  const handleModalClose = useCallback(() => {
    setLocalStorageDismiss(true); // Ensure localStorage is updated
  }, [setLocalStorageDismiss]);

  const handleUpgradeClick = useCallback(() => {
    updateFeature(
      {
        ai_agents: true,
      },
      {
        onSuccess: () => {
          dispatch(popModal());
        },
      }
    );
  }, [dispatch, updateFeature]);

  useEffect(() => {
    const storedDismiss = localStorage.getItem(localStorageKey);

    if (
      isAdminOrOwner &&
      ai_agents_upgrade_available &&
      !storedDismiss &&
      ai_agents === false
    ) {
      dispatch(
        pushModal(MODAL_SHOWCASE, {
          onSubmit: handleUpgradeClick,
          onBlur: handleModalClose,
          cards: newCards(t),
        })
      );

      return () => {
        dispatch(popModal());
      };
    }
  }, [
    ai_agents,
    ai_agents_upgrade_available,
    dispatch,
    handleModalClose,
    handleUpgradeClick,
    isAdminOrOwner,
    localStorageKey,
    t,
  ]);
};
