import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { FieldError, Resolver, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Trash from '@/components/atoms/Icons/Trash';
import Input from '@/components/atoms/Input/Input';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter, scrollToElementById } from '@/util/util';
import { LENGTH_XL } from '@/util/validator';

import { MAX_FORM_CHARACTERS, removeTrailingBRs } from '../../helper';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { PlusIcon } from '../../icons/PlusIcon';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  objections: { message: string; response: string }[];
  message: string;
  response: string;
};

const FORM_ID = 'ai-agent-objections';

const schema = (t: TFunction) =>
  yup
    .object({
      response: yup.string().max(
        MAX_FORM_CHARACTERS,
        t('validation.max_length', {
          max: MAX_FORM_CHARACTERS,
        })
      ),
      message: yup.string().max(
        LENGTH_XL,
        t('validation.max_length', {
          max: LENGTH_XL,
        })
      ),
      objections: yup.array().of(
        yup.object().shape({
          response: yup
            .string()
            .max(
              MAX_FORM_CHARACTERS,
              t('validation.max_length', {
                max: MAX_FORM_CHARACTERS,
              })
            )
            .required(t('validation.required')),
          message: yup.string().max(
            LENGTH_XL,
            t('validation.max_length', {
              max: LENGTH_XL,
            })
          ),
        })
      ),
    })

    .required();

export const Objections = ({ order }: { order: number }) => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();
  const [shouldReset, setShouldReset] = useState(false);
  const { editorKey, forceRerender } = useForceRerender();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    setValue,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<Form>({
    resolver: yupResolver(schema(t)) as Resolver<Form>,
    values: {
      objections: brain?.guidelines?.objections || [],
      message: '',
      response: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'objections',
  });

  const message = watch('message');
  const response = watch('response');
  const isDisabled = !(dirtyFields.objections?.length > 0);

  // Handlers
  const onSubmit = (data: Form) => {
    const cleanedObjections = data.objections.map((objection) => ({
      ...objection,
      response: removeTrailingBRs(objection.response),
    }));

    updateBrain(
      {
        brain_id: brainId,
        guidelines: {
          ...brain?.guidelines,
          objections: cleanedObjections,
        },
      },
      {
        onSuccess: () => {
          reset({
            objections: cleanedObjections,
            message,
            response,
          });
          // Force rerender to adjust the editor height
          forceRerender();
        },
      }
    );
  };

  const addNewObjection = () => {
    setShouldReset(true);
    append({ message, response: removeTrailingBRs(response) }); // Add new objection
    // Reset initial inputs and keep current values of the field array
    reset(
      (formValues) => ({
        ...formValues,
        message: '',
        response: '',
      }),
      {
        keepDirty: true, // This keeps the form dirty to enable the submit button
      }
    );
    setTimeout(() => {
      setShouldReset(false);
    }, 0);
  };

  useTrackFormState({
    isDirty: dirtyFields.objections?.length > 0,
    formId: FORM_ID,
  });

  return (
    <FormCard id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <FormCard.Header
        title={t('ai_agents.knowledge.objections.title')}
        subtitle={t('ai_agents.knowledge.objections.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={order}
          />
        }
      />

      <FormCard.Content>
        <Box className={canWrite ? 'pb-0' : 'pb-4'}>
          <Input
            placeholder={t(
              'ai_agents.knowledge.objections.message_placeholder'
            )}
            size="large"
            label={t('ai_agents.knowledge.objections.objection')}
            disabled={!canWrite}
            {...register('message')}
            // Use onKeyDown instead of onKeyUp to prevent the form from submitting
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                // Focus on next input
                setFocus('response');
              }
            }}
          />
        </Box>

        <MarkdownEditor
          name="response"
          setValue={setValue}
          label={t('ai_agents.knowledge.objections.example_response')}
          placeholder={t('ai_agents.knowledge.objections.response_placeholder')}
          reset={shouldReset}
          disabled={!canWrite}
          key={editorKey}
          value={watch('response')}
        />

        <Box my="var(--space-24)">
          <Button
            variant="tertiary"
            disabled={!(message && response) || !canWrite}
            onClick={addNewObjection}
            type="button"
          >
            <PlusIcon color="currentColor" />
            {t('ai_agents.knowledge.add_objection')}
          </Button>
        </Box>

        <Box height={1} component="hr" />

        {fields.map((field, index) => {
          return (
            <Stack key={field.id} my="var(--space-16)" id={field.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="var(--space-4)"
              >
                <Typography
                  variant="label-caps-large"
                  color="var(--text-default-gray)"
                >
                  #{index + 1}
                </Typography>

                <IconButton
                  onClick={() => remove(index)}
                  ariaLabel={t('common.delete')}
                >
                  <Trash />
                </IconButton>
              </Box>

              <Input
                size="large"
                {...register(`objections.${index}.message`)}
                defaultValue={field.message}
                error={!!errors.objections?.[index]?.message}
                errorMessage={capitalizeFirstLetter(
                  (errors.objections?.[index]?.message as FieldError)?.message
                )}
                disabled={!canWrite}
              />

              <Box mt="var(--space-8)">
                <MarkdownEditor
                  name={`objections.${index}.response`}
                  setValue={setValue}
                  defaultValue={field.response}
                  placeholder={t('ai_agents.knowledge.features.placeholder')}
                  error={!!errors.objections?.[index]?.response}
                  errorMessage={capitalizeFirstLetter(
                    errors.objections?.[index]?.response?.message
                  )}
                  onClick={() => scrollToElementById(field.id)}
                  shouldExpand
                  disabled={!canWrite}
                  key={editorKey}
                  value={watch(`objections.${index}.response`)}
                />
              </Box>
            </Stack>
          );
        })}
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={isDisabled}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
