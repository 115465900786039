import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';

import { AudioIcon } from '@/components/atoms/Icons/AudioIcon';
import {
  ConversationSource,
  Attachment as AttachmentType,
} from '@/models/chat';
import { Entity } from '@/models/tryIt';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import Attachments from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachments';
import { splitArray } from '@/util/util';

import { highlightEntities } from './helper';

import styles from './PromptMessage.module.scss';
interface PromptMessageProps {
  text: string;
  entities: Entity[];
  onGoToEntity: (entity: string) => void;
  t: TFunction;
  isAudio: boolean;
  attachments: AttachmentType[];
  source: ConversationSource;
}

export const PromptMessage = ({
  text,
  entities,
  onGoToEntity,
  t,
  isAudio,
  attachments,
  source,
}: PromptMessageProps) => {
  return (
    <div className={styles.prompt}>
      {attachments && (
        <Box mb={text ? 1 : 0}>
          {splitArray(attachments, 4).map((chunk, chunkIndex) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={chunkIndex}
            >
              <Attachments>
                {chunk.map((attachment) => (
                  <Attachment
                    key={attachment?.id ?? attachment.url}
                    attachment={attachment}
                    source={source}
                    isSingleItem={attachments.length === 1}
                    bg="dark"
                    authorType="visitor"
                  />
                ))}
              </Attachments>
            </div>
          ))}
        </Box>
      )}
      {isAudio && (
        <span className={styles.audioTitle}>
          <AudioIcon color="var(--text-default-white)" />
          <Typography
            variant="label-regular"
            color="var(--text-default-white)"
            className={styles.audio}
          >
            {t('conversation.audio_message')}
          </Typography>
        </span>
      )}

      <Typography variant="body-regular" component="p">
        {highlightEntities(text, entities, onGoToEntity)}
      </Typography>
    </div>
  );
};
