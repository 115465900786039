import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { ConversationSource } from '@/models/chat';
import { BroadcastAction } from '@/modules/broadcast/models';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import Attachments from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachments';
import { splitArray } from '@/util/util';

import styles from '../MessageNode/MessageNode.module.scss';

interface Props {
  text: string;
  author_type: string;
  author_id: string;
  template?: BroadcastAction;
  body: Record<string, string>;
  source?: ConversationSource;
}

export const AgentMessage = ({
  text,
  template,
  author_type,
  author_id,
  body,
  source,
}: Props) => {
  const { html } = useMarkdownToHtml(text);
  const options = template?.components
    ?.find((component) => component.type.toLowerCase() === 'buttons')
    ?.buttons?.map((option) => ({
      text: option.text,
      label: option.text,
    }));
  const attachments = Array.isArray(body?.attachments) ? body?.attachments : [];
  return (
    <>
      {body?.type === 'audio' ? (
        <>
          <audio src={body.url} controls />
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            {author_type === 'agent' && (
              <Avatar size="medium" userId={author_id} />
            )}

            <div className={cn(styles.bubble, styles.agent)}>
              {attachments && (
                <Box mb={text ? 1 : 0}>
                  {splitArray(attachments, 4).map((chunk, chunkIndex) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={chunkIndex}
                    >
                      <Attachments>
                        {chunk.map((attachment) => (
                          <Attachment
                            key={attachment?.id ?? attachment.url}
                            attachment={attachment}
                            source={source}
                            isSingleItem={attachments.length === 1}
                            bg="light"
                            authorType="agent"
                          />
                        ))}
                      </Attachments>
                    </div>
                  ))}
                </Box>
              )}

              <Typography
                variant="body-regular"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </div>
          </Box>
          <div className={styles.optionsContainer}>
            {template &&
              options?.map((option) => (
                <button
                  key={`option-${option.text}`}
                  className={cn(
                    styles.bubble,
                    styles.option,
                    styles.optionDisabled
                  )}
                  onClick={() => {}}
                  type="button"
                >
                  {option.label}
                </button>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default AgentMessage;
