import { withProps } from '@udecode/cn';
import {
  BoldPlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { CodeBlockPlugin } from '@udecode/plate-code-block/react';
import {
  ParagraphPlugin,
  PlateElement,
  PlateLeaf,
  usePlateEditor,
} from '@udecode/plate-common/react';
import { EmojiInputPlugin } from '@udecode/plate-emoji/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import {
  BulletedListPlugin,
  ListItemPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { deserializeMd } from '@udecode/plate-markdown';
import {
  MentionInputPlugin,
  MentionPlugin,
} from '@udecode/plate-mention/react';
import { SlashInputPlugin } from '@udecode/plate-slash-command/react';
import remarkEmoji from 'remark-emoji';
import { Processor } from 'unified';

import { plugins, editorPlugins } from './editor-plugins';
import { EmojiInputElement } from '../plate-ui/emoji-input-element';
import { LinkElement } from '../plate-ui/link-element';
import { ListElement } from '../plate-ui/list-element';
import { MentionElement } from '../plate-ui/mention-element';
import { MentionInputElement } from '../plate-ui/mention-input-element';
import { SlashInputElement } from '../plate-ui/slash-input-element';

interface Props {
  defaultValue?: string;
  readOnly?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCreateEditor = ({ defaultValue, readOnly }: Props): any => {
  const editor = usePlateEditor({
    override: {
      components: {
        [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
        [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
        [ParagraphPlugin.key]: withProps(PlateElement, {
          as: 'p',
          className: 'text-sm mb-1 relative',
        }),
        [ListItemPlugin.key]: withProps(PlateElement, {
          as: 'li',
          className: 'text-sm',
        }),
        [BulletedListPlugin.key]: withProps(ListElement, {
          variant: 'ul',
          className: 'text-sm',
        }),
        [NumberedListPlugin.key]: withProps(ListElement, {
          variant: 'ol',
          className: 'text-sm',
        }),
        [CodeBlockPlugin.key]: withProps(PlateElement, {
          as: 'pre',
          className: 'text-sm mb-1 relative bg-slate-100 rounded',
        }),
        [StrikethroughPlugin.key]: withProps(PlateLeaf, { as: 's' }),
        [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
        [SlashInputPlugin.key]: SlashInputElement,
        [MentionPlugin.key]: MentionElement,
        [MentionInputPlugin.key]: MentionInputElement,
        [LinkPlugin.key]: LinkElement,
        [EmojiInputPlugin.key]: EmojiInputElement,
        h1: withProps(PlateElement, {
          as: 'h1',
          className:
            'mb-4 mt-6 text-3xl font-semibold tracking-tight lg:text-4xl',
        }),
        h2: withProps(PlateElement, {
          as: 'h2',
          className: 'mb-4 mt-6 text-2xl font-semibold tracking-tight',
        }),
        h3: withProps(PlateElement, {
          as: 'h3',
          className: 'mb-4 mt-6 text-xl font-semibold tracking-tight',
        }),
      },
    },
    plugins: readOnly ? plugins : [...plugins, ...editorPlugins],
    value: (editor) =>
      deserializeMd(editor, defaultValue, {
        processor(processor: Processor) {
          return processor.use(remarkEmoji) as Processor;
        },
      }),
  });

  return editor;
};
