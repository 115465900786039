import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_XS } from '@/util/validator';

import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  name: string;
};

const FORM_ID = 'ai-agent-company-name';

interface Props {
  number: number;
}

export const CompanyName = ({ number }: Props) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      name: brain?.guidelines?.name ?? '',
    },
  });

  const onSubmit = ({ name }: Form) => {
    // Prevent the form from being submitted if the values have not changed
    if (!isDirty) {
      return;
    }

    updateBrain({
      guidelines: {
        ...brain.guidelines,
        // Return undefined if the name is empty
        name: name || undefined,
      },
      brain_id: brainId,
    });
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.company_name.title')}
        subtitle={t('ai_agents.company_name.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <Input
          label={t('ai_agents.company_name.label')}
          {...register('name', {
            maxLength: {
              value: LENGTH_XS,
              message: t('validation.max_length', { max: LENGTH_XS }),
            },
          })}
          placeholder={t('ai_agents.company_name.placeholder')}
          error={!!errors.name}
          errorMessage={capitalizeFirstLetter(errors.name?.message)}
          disabled={!canWrite}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
