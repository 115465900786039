import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import CodeBlock from '@/components/atoms/CodeBlock/CodeBlock';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Select from '@/components/atoms/Select/Select';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import {
  AccordionFieldsetProps,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { getWebClientSDK } from '@/util/util';

import styles from './WebchatPosition.module.scss';

type Form = {
  widget_position: string;
};

const positionOptions = [
  { value: 'livechat', label_key: 'integrations.web_position.livechat' },
  { value: 'embed', label_key: 'integrations.web_position.embed' },
  { value: 'popup', label_key: 'integrations.web_position.popup' },
];

const WebchatPosition = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, WebWidgetIntegration>) => {
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const dispatch = useDispatch();
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(
      integration?.desk_id,
      integration?.integration_id
    );

  const formMethods = useForm<Form>({ mode: 'onSubmit' });
  const {
    register,
    reset,
    formState: { isDirty },
    watch,
    trigger,
    handleSubmit,
  } = formMethods;

  useEffect(() => {
    reset({
      widget_position: integration?.config?.widget_position || 'livechat',
    });
  }, [integration?.config?.widget_position, reset]);

  const isEmbed = watch('widget_position') === 'embed';

  const onSubmit = useCallback(() => {
    const { widget_position } = formMethods.getValues();
    updateIntegration(
      {
        ...integration,
        config: {
          ...integration?.config,
          widget_position,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [dispatch, expanded, formMethods, integration, type, updateIntegration]);

  usePrompt(isDirty && canWrite, undefined, undefined, handleSubmit(onSubmit));

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [dispatch, expanded, isDirty, reset, type]);

  const Snippet = () => {
    return (
      <>
        <span>{`<script src="${getWebClientSDK()}"></script>
    <script>
      MoveoAI.init({ integrationId: "${integration?.integration_id}",`}</span>
        <span
          style={{ color: 'green' }}
        >{`element:document.getElementById('embed')`}</span>
        <span>{`}).then((desk) => console.log("Moveo connected")).catch((error) => console.error(error));
    </script>`}</span>
      </>
    );
  };

  const codeSnippet = `<script src="${getWebClientSDK()}"></script>
  <script>
    MoveoAI.init({ integrationId: "${integration?.integration_id}",element:document.getElementById('embed')}).then((desk) => console.log("Moveo connected")).catch((error) => console.error(error));
  </script>`;

  return (
    <>
      <Accordion
        title={t('integrations.web_position.title')}
        subtitle={t('integrations.web_position.subtitle')}
        footerText={t('integrations.web_position.footer')}
        onSubmit={handleSubmit(onSubmit)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(type, handleSubmit(onSubmit), trigger)}
      >
        <Select
          label={t('integrations.web_position.select')}
          register={register('widget_position')}
          name="widget_position"
          options={positionOptions}
          size="large"
          disabled={!canWrite}
        />
        {isEmbed && (
          <div className={styles.embed}>
            <TitleSubtitle
              title={t('integrations.web_position.title2')}
              subtitle={t('integrations.web_position.subtitle2')}
            />
            <div className={styles.codeSnippet}>
              <div className={styles.codeBlockBontainer}>
                <CodeBlock>
                  <Snippet />
                </CodeBlock>
                {codeSnippet && (
                  <CopyButton
                    className={styles.copyCodeSnippet}
                    data={codeSnippet}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Accordion>
    </>
  );
};

export default WebchatPosition;
