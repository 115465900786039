import { useContext, useEffect, useCallback } from 'react';

import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { SocketContext } from '@/contexts/rtm';
import useDesktopNotifications from '@/hooks/useDesktopNotifications';
import { onMessageUpdated, onMessageCreated } from '@/hooks/useMessages';
import useMessageSound from '@/hooks/useMessageSound';
import { Conversations, MarkMessages } from '@/models/chat';
import { NewMessage, SocketEvent, UserEvent } from '@/models/rtm';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import {
  conversationKeys,
  sorts,
  statuses,
  updateConversationInPages,
} from '@/modules/humanChat/hooks/useConversations';
import messageSound from '@/util/message.mp3';

import { useAccount } from '../useAccount';

const useRtmMessages = (deskId: string, session_id: string) => {
  const { t } = useTranslation();
  const { showNotifications } = useDesktopNotifications();
  const { accountId: account_id } = useAccount();
  const { departments } = useDepartments(deskId);
  const { play } = useMessageSound(messageSound);
  const { slug } = useAccount();
  const { socket, isConnected } = useContext(SocketContext);
  const queryClient = useQueryClient();

  const handleNewMessage = useCallback<UserEvent<NewMessage>>(
    ({ data }) => {
      if (data.session_id === session_id) {
        onMessageCreated(queryClient, deskId, data);
      }
      if (data.author_type === 'visitor' && data.session_id === session_id) {
        showNotifications({
          url: `/${slug}/chats/${deskId}/conversations/${data.session_id}`,
          text: t('conversation.you_have_a_new_message'),
        });
        play();
      }
    },
    [deskId, play, queryClient, session_id, showNotifications, slug, t]
  );

  const handleMessageRead = useCallback<UserEvent<MarkMessages>>(
    ({ data }) => {
      onMessageUpdated(queryClient, deskId, data.session_id);

      const updateConvs = (key: readonly string[]) => {
        queryClient.setQueryData<InfiniteData<Conversations>>(key, (prev) => {
          const [newPrev] = updateConversationInPages(prev, {
            session_id: data.session_id,
            desk_id: deskId,
            agent_unread_count: 0,
          });
          if (!newPrev) {
            return newPrev;
          }
          return { pages: newPrev.pages, pageParams: newPrev.pageParams };
        });
      };

      if (data.author_type === 'agent') {
        // Update the conversation for department cache
        departments?.forEach((department) => {
          statuses.forEach((status) => {
            sorts.forEach((sort) =>
              updateConvs(
                conversationKeys.byDepartment(
                  deskId,
                  status,
                  sort,
                  department.department_id
                )
              )
            );
          });
        });
      }
    },
    [departments, deskId, queryClient]
  );

  const handleMessagesDelivered = useCallback<UserEvent<MarkMessages>>(
    ({ data }) => {
      onMessageUpdated(queryClient, deskId, data.session_id);
    },
    [deskId, queryClient]
  );

  useEffect(() => {
    socket?.on(SocketEvent.messages_read, handleMessageRead);
    socket?.on(SocketEvent.messages_delivered, handleMessagesDelivered);
    return () => {
      socket?.off(SocketEvent.messages_delivered, handleMessagesDelivered);
      socket?.off(SocketEvent.messages_read, handleMessageRead);
    };
  }, [handleMessageRead, handleMessagesDelivered, socket]);

  useEffect(() => {
    socket?.on(SocketEvent.messages_created, handleNewMessage);
    return () => {
      socket?.off(SocketEvent.messages_created, handleNewMessage);
    };
  }, [handleNewMessage, socket]);

  useEffect(() => {
    if (!socket || !session_id || !account_id || !isConnected) {
      return;
    }
    socket.emit(SocketEvent.subscribe_conversation, { account_id, session_id });
    return () => {
      socket.emit(SocketEvent.unsubscribe_conversation, {
        account_id,
        session_id,
      });
    };
  }, [isConnected, session_id, account_id, socket]);
};

export default useRtmMessages;
