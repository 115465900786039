import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

import DialogEditor from '../../Dialogs/DialogEditor';

import styles from './ModalPreviewDialog.module.scss';

interface Props {
  name: string;
  dialogId: string;
}

export const ModalPreviewDialog = ({ name, dialogId }: Props) => {
  const brainId = useSelector(selectBrainId);
  const slug = useSelector(selectAccountSlug);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(popModal());
  };

  return (
    <Modal
      aria-labelledby="create-custom-guideline-title"
      aria-describedby="create-custom-guideline-description"
      open={true}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={true}>
        <div className={styles.modal}>
          <IconButton
            className={styles.modal__closeIcon}
            onClick={closeModal}
            ariaLabel={t('modals.close')}
          >
            <IconClose color="var(--icon-default-gray)" size={20} />
          </IconButton>
          <section className={styles.modal__content}>
            <header>
              <Typography variant="h2-semi-bold" component="h1">
                {`${name} ${t('dialog.actions.preview').toLowerCase()}`}
              </Typography>
            </header>

            <div className={styles.modal__preview}>
              <DialogEditor />
            </div>

            <footer className={styles.modal__footer}>
              <Button
                onClick={() => {
                  navigate(
                    `/${slug}/ai-agents/${brainId}/conversation/dialogs/${dialogId}`
                  );
                  dispatch(popModal());
                }}
                size="large"
                type="button"
                variant="secondary"
              >
                {t('ai_agents.reminder.go_to_dialog')}
              </Button>
            </footer>
          </section>
        </div>
      </Fade>
    </Modal>
  );
};
