// Material Components
import './Switch.scss';

import MaterialSwitch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

interface Props extends Omit<SwitchProps, 'size'> {
  label?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  size?: string;
  onChange?: (
    event?: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean
  ) => void;
  reverse?: boolean;
}

const Switch = ({
  label = '',
  name = 'untitled_switch',
  required = false,
  checked = true,
  disabled = false,
  tooltip = '',
  size = 'small',
  onChange = () => {},
  reverse = false,
  ...props
}: Props) => {
  return (
    <div
      className={cn('switch', {
        switch__disabled: disabled,
        ['switch--reverse']: reverse,
      })}
    >
      <div className={cn(`switch__input ${size}`)}>
        <MaterialSwitch
          disableRipple
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          {...props}
        />
      </div>
      {label && (
        <Typography component="label" className="switch__label" htmlFor={name}>
          {label}
          {required && '*'}
          <HelpTooltip tooltip={tooltip} />
        </Typography>
      )}
    </div>
  );
};
export default Switch;
