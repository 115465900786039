import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import ZoomResetIcon from '@/components/atoms/Icons/ZoomReset';
import ToolkitControlsWrapper from '@/components/organisms/Dialogs/Node/ToolkitControls/ToolkitControlsWrapper/ToolkitControlsWrapper';

import ToolkitControlsZoom from './ToolkitControlsZoom';

import styles from './ToolkitControls.module.scss';

const ToolkitControls = ({
  onZoomInClick,
  onZoomOutClick,
  onZoomResetClick,
  zoomPercentage,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isDialogPreview = location.pathname.endsWith('/setup');

  return (
    <>
      <div
        className={cn(
          styles.toolkit__controls,
          styles['toolkit__controls--zoom'],
          {
            [styles['toolkit__controls--zoom-preview']]: isDialogPreview,
          }
        )}
      >
        <ToolkitControlsZoom
          onZoomInClick={onZoomInClick}
          onZoomOutClick={onZoomOutClick}
          zoomPercentage={zoomPercentage}
        />
      </div>

      <div
        className={cn(
          styles.toolkit__controls,
          styles['toolkit__controls--reset'],
          {
            [styles['toolkit__controls--reset-preview']]: isDialogPreview,
          }
        )}
      >
        <ToolkitControlsWrapper
          items={[
            {
              label: t('common.zoom_in'),
              icon: <ZoomResetIcon />,
              onClick: onZoomResetClick,
              shouldFocus: true,
            },
          ]}
        />
      </div>
    </>
  );
};

export default ToolkitControls;
