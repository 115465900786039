import { useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { EmojiClickData } from 'emoji-picker-react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '@/components/atoms/Switch/Switch';
import { CONDITION_ATTRIBUTES_LABEL_TO_VALUE_VIEWS } from '@/components/organisms/Condition/constants';
import { useViews } from '@/hooks/useViews';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { ViewType } from '@/models/views';
import { clearCondition, setCondition } from '@/redux/condition/actions';
import {
  selectCondition,
  selectIsConditionEmpty,
  selectIsConditionError,
} from '@/redux/condition/selectors';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';
import { capitalizeFirstLetter } from '@/util/util';

import Condition from '../../Condition/Condition';
import Modal from '../Modal';
import { EmojiInput } from './EmojiInput/EmojiInput';
import { schema } from './schemas';

import styles from './ModalViews.module.scss';

const DEFAULT_ICON = '/assets/white_flag_emoji.png';
interface Form {
  name: string;
  icon: string;
  type: ViewType;
}

type ModalViewsProps = {
  viewId?: string;
};

export const ModalViews = ({ viewId }: ModalViewsProps) => {
  const dispatch = useDispatch();
  const { updateView, createView, view, isLoading, isValidName, isViewOwner } =
    useViews(viewId);
  const { t } = useTranslation();
  const [isEmojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const condition = useSelector(selectCondition);
  const isConditionEmpty = useSelector(selectIsConditionEmpty);
  const innerConditionError = useSelector(selectIsConditionError);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'views', actions.WRITE)
  );

  const [conditionError, setConditionError] = useState<string | undefined>();

  const defaultValues = useMemo(
    () => ({
      name: viewId ? view.name : '',
      type: viewId ? view.type : ViewType.PUBLIC,
      icon: viewId ? view.icon : DEFAULT_ICON,
    }),
    [viewId, view]
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver<Form>(schema(t)) as Resolver<Form>,
  });

  useEffect(() => {
    if (viewId) {
      dispatch(setCondition(view?.condition));
    }
  }, [view, dispatch, viewId]);

  const onSuccess = useCallback(() => {
    dispatch(popModal());
    dispatch(clearCondition());
  }, [dispatch]);

  const validateName = useCallback(async () => {
    const name = getValues('name');
    const validName = await isValidName(name);

    if (!validName) {
      setError('name', { message: t('conversation.views.name_taken') });
      return true;
    }
    return false;
  }, [getValues, isValidName, setError, t]);

  const onSubmit = useCallback<(form: Form) => void>(
    async (form) => {
      let error = false;

      error = await validateName();

      if (isConditionEmpty || innerConditionError) {
        setConditionError(t('conversation.views.empty_condition_error'));
        error = true;
      }
      // Instead of returning in the conditionals above we make sure that in case of having error
      // in both fields we don't show only one because of an early return
      if (error) {
        return;
      }

      const view = { ...form, condition };
      // Users that are not the owner of the view can't change the type
      if (viewId) {
        if (!isViewOwner) {
          delete view.type;
        }
        trackEvent(EventName.UpdateView);
        updateView({ view_id: viewId, view }, { onSuccess });
      } else {
        trackEvent(EventName.CreateView, view);
        createView(view, { onSuccess });
      }
    },
    [
      validateName,
      isConditionEmpty,
      innerConditionError,
      condition,
      viewId,
      t,
      isViewOwner,
      updateView,
      onSuccess,
      createView,
    ]
  );

  const handleSetEmoji = (emoji: EmojiClickData) => {
    setValue('icon', emoji.imageUrl);
  };

  const handleOnClose = useCallback(() => {
    dispatch(clearCondition());
  }, [dispatch]);

  return (
    <Modal
      preventClose={isEmojiMenuOpen}
      isSubmitting={isLoading}
      size="medium"
      title={t(
        viewId
          ? 'conversation.views.modal_title_edit'
          : 'conversation.views.modal_title_create'
      )}
      onPrimarySubmit={handleSubmit(onSubmit)}
      onSecondarySubmit={handleOnClose}
      primaryButtonText={viewId ? t('common.save') : t('common.create')}
    >
      <EmojiInput
        onBlur={validateName}
        register={register('name')}
        label={t('field.name')}
        placeholder={t('conversation.views.modal_placeholder')}
        onEmojiClick={handleSetEmoji}
        error={Boolean(errors?.name?.message)}
        errorMessage={capitalizeFirstLetter(errors?.name?.message) as string}
        emojiPlaceholder={view?.icon}
        setEmojiMenuOpen={setEmojiMenuOpen}
        isEmojiMenuOpen={isEmojiMenuOpen}
      />
      {isViewOwner && (
        <Controller
          control={control}
          name="type"
          render={({ field }) => {
            return (
              <Switch
                size="medium"
                checked={field.value === ViewType.PRIVATE}
                onChange={(e) =>
                  field.onChange(
                    e.target.checked ? ViewType.PRIVATE : ViewType.PUBLIC
                  )
                }
                label={t('conversation.views.private_view')}
                tooltip={t('conversation.views.visibility_tooltip')}
              />
            );
          }}
        />
      )}
      <div className={styles.condition}>
        <Typography
          variant="label-caps-large"
          color="var(--text-default-gray-light)"
        >
          {t('common.filters')}
        </Typography>
        <Condition
          attributes={CONDITION_ATTRIBUTES_LABEL_TO_VALUE_VIEWS}
          error={conditionError}
          canWrite={canWrite}
        />
      </div>
    </Modal>
  );
};
