import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Docs from '@/components/atoms/Icons/Docs';
import Switch from '@/components/atoms/Switch/Switch';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { RootState } from '@/models/state';
import { selectSelectedNode } from '@/redux/dialogs/selectors';
import { updateNode } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { EVENT_UNKNOWN_DOCS_URL } from '@/util/constants';

import ToolkitWrapper from '../ToolkitWrapper';
import { AssignedReminders } from './AssignedReminders';

type FormType = {
  enhance: boolean;
};

const ToolkitNodeUnknown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { ai_agents, ai_agents_reminder } = useFeatureFlag();

  const isEnhanceAvailable = brain?.language === 'en';
  const { nodeId, enhance, isRootNodeUnknown } = useSelector(
    (state: RootState) => {
      const selectedNode = selectSelectedNode(state);
      const isRootNodeUnknown = state.nodes?.nodes[0]?.type === 'unknown';

      return {
        nodeId: selectedNode.node_id,
        enhance: selectedNode?.enhance ?? false,
        isRootNodeUnknown,
      };
    },
    shallowEqual
  );

  const { getValues, control, reset } = useForm<FormType>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEnhanceAvailable) {
      reset({ enhance: enhance });
    }
  }, [isEnhanceAvailable, enhance, reset]);

  const onChange = useCallback(async () => {
    const values = getValues();

    dispatch(
      updateNode({
        nodeId,
        node: { enhance: values?.enhance ?? false },
      })
    );
  }, [dispatch, getValues, nodeId]);

  return (
    <ToolkitWrapper type={isRootNodeUnknown ? 'unknown_root' : 'unknown'}>
      <ContextualHelp
        preventClose
        name="action-close"
        title={t('dialog.unknown.title')}
        links={[
          {
            label: t('docs.docs'),
            url: EVENT_UNKNOWN_DOCS_URL,
            icon: <Docs size={16} color="currentColor" />,
          },
        ]}
      >
        {t('dialog.unknown.body')}
      </ContextualHelp>
      {isEnhanceAvailable && (
        <Controller
          name="enhance"
          control={control}
          render={({ field: { value, onChange: onControllerChange } }) => {
            return (
              <Switch
                reverse
                label={t('dialog.unknown.enhance_with_ai')}
                checked={value}
                size="medium"
                tooltip={t('dialog.unknown.enhance_with_ai_tooltip')}
                onChange={(event) => {
                  onControllerChange(event);
                  onChange();
                }}
              />
            );
          }}
        />
      )}

      {ai_agents && ai_agents_reminder && (
        <Box my="var(--space-24)">
          <AssignedReminders />
        </Box>
      )}
    </ToolkitWrapper>
  );
};

export default ToolkitNodeUnknown;
