import { memo, useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import { setDraftRule } from '@/modules/rules/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { errorMessage, ruleRules } from '@/util/validator';

import Modal from './Modal';
import {
  ACTIONS_VALUE_TO_LABEL,
  TRIGGERS_VALUE_TO_LABEL,
} from '../Condition/constants';

function ModalRuleCreation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const { slug } = useAccount();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      metadata: {
        source: 'manual',
      },
    },
  });
  const dispatch = useDispatch();

  const triggers = TRIGGERS_VALUE_TO_LABEL.map((x) => ({
    type: x,
  }));

  const actionOptions = ACTIONS_VALUE_TO_LABEL.map((x) => ({
    type: x,
  }));

  const draftRule = useMemo(() => {
    return {
      triggers: [triggers[0]],
      options: triggers,
      condition: {
        operator: 'or',
        conditions: [],
      },
      actions: [],
      actionOptions,
      status: 'inactive',
      dirty: false,
    };
  }, [actionOptions, triggers]);

  const onSubmit = useCallback(
    (rule) => {
      navigate(`/${slug}/environments/${deskId}/rules/draft`);
      dispatch(
        setDraftRule({
          ...draftRule,
          ...rule,
        })
      );
      dispatch(popModal());
    },
    [deskId, dispatch, draftRule, navigate, slug]
  );

  return (
    <Modal
      title={t('rules.create_a_rule')}
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.create')}
      autoFocus
    >
      <form autoComplete="off">
        <div className="input__container">
          <Input
            name="name"
            label={t('field.name')}
            register={register('name', ruleRules.name)}
            errorMessage={errorMessage({
              field: errors.name,
              maxLength: ruleRules.name.maxLength,
            })}
            placeholder={t('common.name_placeholder')}
            size="medium"
            autoFocus
          />
        </div>
        <div className="input__container">
          <Input
            name="description"
            label={t('common.description')}
            register={register('description', ruleRules.description)}
            errorMessage={errorMessage({
              field: errors.description,
              maxLength: ruleRules.description.maxLength,
            })}
            placeholder={t('common.description_placeholder')}
            size="medium"
          />
        </div>
      </form>
    </Modal>
  );
}

export default memo(ModalRuleCreation);
