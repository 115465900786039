import { Icon } from '@/models/icon';

export const PublicView = ({
  color = 'var(--icon-default-gray)',
  size = 16,
}: Icon) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.67 8S3.33 2.67 8 2.67 15.33 8 15.33 8 12.67 13.33 8 13.33.67 8 .67 8Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
