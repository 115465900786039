import { Props } from '@/models/icon';

export const StaticKnowledgeIcon = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M13 2H4.5a2 2 0 0 0-2 2v10.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 0-1H3.5a1 1 0 0 1 1-1H13a.5.5 0 0 0 .5-.5v-10A.5.5 0 0 0 13 2ZM7.5 3h3v4.5l-1.2-.9a.5.5 0 0 0-.6 0l-1.2.9V3Zm5 9h-8c-.351 0-.696.092-1 .268V4a1 1 0 0 1 1-1h2v5.5a.5.5 0 0 0 .8.4L9 7.625 10.7 8.9a.5.5 0 0 0 .8-.4V3h1v9Z"
      />
    </svg>
  );
};
