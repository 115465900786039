import { useCallback } from 'react';

import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useTrackFormState } from '@/modules/aiAgents/hooks/useTrackFormState';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';

import { InactivityTimeoutOption } from './InactivityTimeoutOption';

export interface FormValues {
  inactivity_timeout: number;
}

export const INACTIVITY_TIMEOUT_OPTIONS = (t: TFunction) => [
  {
    label: `5 ${t('common.minutes').toLowerCase()}`,
    value: 5 * 60,
  },
  {
    label: `15 ${t('common.minutes').toLowerCase()}`,
    value: 15 * 60,
  },
  {
    label: `30 ${t('common.minutes').toLowerCase()}`,
    value: 30 * 60,
  },
  {
    label: `45 ${t('common.minutes').toLowerCase()}`,
    value: 45 * 60,
  },
  {
    label: `1 ${t('common.hour').toLowerCase()}`,
    value: 60 * 60,
  },
  {
    label: `2 ${t('common.hours').toLowerCase()}`,
    value: 2 * 60 * 60,
  },
  {
    label: `8 ${t('common.hours').toLowerCase()}`,
    value: 8 * 60 * 60,
  },
  {
    label: `24 ${t('common.hours').toLowerCase()}`,
    value: 24 * 60 * 60,
  },
];

// Helper function to find the closest option value
export const getClosestTimeout = (value: number | undefined, t: TFunction) => {
  if (!value) {
    return INACTIVITY_TIMEOUT_OPTIONS(t)[0].value;
  }
  return INACTIVITY_TIMEOUT_OPTIONS(t).reduce((prev, curr) =>
    Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev
  ).value;
};

const FORM_ID = 'ai-agent-inactivity-timeout';

export const InactivityTimeout = () => {
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);

  const { brain, getStatus, updateBrain, updateStatus } = useBrains(brainId);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty },
  } = useForm<FormValues>({
    values: {
      inactivity_timeout: getClosestTimeout(brain?.inactivity_timeout, t),
    },
  });

  useTrackFormState({ isDirty, formId: FORM_ID });

  const onSubmit = useCallback(
    ({ inactivity_timeout }: FormValues) => {
      updateBrain({
        brain_id: brainId,
        inactivity_timeout,
      });
    },
    [brainId, updateBrain]
  );

  const handleClick = (option) => {
    setValue('inactivity_timeout', option.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  return (
    <FormFieldset
      title={t('brains.inactivity.title')}
      subtitle={t('brains.inactivity.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={getStatus === 'pending'}
    >
      <div className="mt-4 grid grid-cols-4 gap-2">
        <Controller
          name="inactivity_timeout"
          control={control}
          render={({ field }) => (
            <>
              {INACTIVITY_TIMEOUT_OPTIONS(t).map((option) => (
                <InactivityTimeoutOption
                  key={option.value}
                  onClick={handleClick}
                  option={option}
                  isSelected={field.value === option.value}
                  disabled={!canWrite}
                />
              ))}
            </>
          )}
        />
      </div>
    </FormFieldset>
  );
};
