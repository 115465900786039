import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import {
  CHANNEL_VALUE_TO_LABEL,
  RULES_ATTRIBUTES_LABEL_TO_VALUE,
  RULES_ATTRIBUTES_VALUE_TO_LABEL,
  RULES_CONDITON_RADIO_OPTIONS,
} from '@/components/organisms/Condition/constants';
import useBusinessHours from '@/hooks/useBusinessHours';
import { useIntegrations } from '@/hooks/useIntegrations';
import useMembers from '@/hooks/useMembers';
import {
  replaceRuleCondition,
  isConditionOpen,
} from '@/redux/condition/actions';
import { selectDeskId } from '@/redux/session/selectors';
import {
  countryListAllIsoData,
  COUNTRIES_VALUE_TO_LABEL,
} from '@/util/countries';
import { languageCodes, languageCodesLabelToValue } from '@/util/languageCodes';
import { timezones } from '@/util/timezones';
import { isKeyEnter, timezoneMaker } from '@/util/util';
import { isValidSlug } from '@/util/validator';

import ConditionsModal from '../ConditionsModal/ConditionsModal';

import styles from './ConditionConfigurationModal.module.scss';

interface Props {
  buttonText?: string;
  index: number;
  outerIndex: number;
  conditionAttribute?: string;
  conditionComparison?: string;
  conditionCategory?: string;
  conditionValue?: string;
  conditionAttributeSubcategory?: string;
  isConditionOpen?: boolean;
  handleApply?: () => void;
}

type Option = {
  id?: number;
  label?: string;
  value?: string;
  type?: string;
};

const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '30px',
    paddingLeft: '16px',
    fontFamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    width: '160px',
    '&.Mui-focused': {
      border: '1px solid var(--border-default-blue)', // focus
    },
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
};

const ConditionConfigurationModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      buttonText,
      conditionAttribute,
      conditionCategory,
      conditionComparison,
      conditionValue,
      conditionAttributeSubcategory,
      index,
      outerIndex,
      handleApply,
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const conditionButtonText = buttonText || t('common.apply');
    const { members } = useMembers();
    const deskId = useSelector(selectDeskId);
    const { integrations } = useIntegrations(deskId);
    const { businessHours } = useBusinessHours();
    const [hasClickedAttribute, setHasClickedAttribute] = useState(false);
    const [hasClickedComparison, setHasClickedComparison] = useState(false);
    const [attribute, setAttribute] = useState('');
    const [comparison, setComparison] = useState('');
    const [comparisonInitial, setComparisonInitial] =
      useState(conditionComparison);
    const [comparisonOptions, setComparisonOptions] = useState([]);
    const [language, setLanguage] = useState('English');
    const [country, setCountry] = useState('');
    const [timezone, setTimezone] = useState(
      timezoneMaker(['Europe/London'])[0]
    );
    const [business, setBusiness] = useState<Option>({});
    const [hasClickedLanguage, setHasClickedLanguage] = useState(false);
    const [hasClickedCountry, setHasClickedCountry] = useState(false);
    const [hasClickedTimezone, setHasClickedTimezone] = useState(false);
    const [input, setInput] = useState<string | null>(conditionValue);
    const dispatch = useDispatch();
    const [agent, setAgent] = useState<Option>({});
    const [integration, setIntegration] = useState<Option>({});
    const [hasClickedAgent, setHasClickedAgent] = useState(false);
    const [hasClickedBusiness, setHasClickedBusiness] = useState(false);
    const [hasClickedChannel, setHasClickedIntegration] = useState(false);
    const [inputHasError, setInputHasError] = useState(false);
    const agentOptions = members?.map((x) => ({
      value: x.user_id,
      label: x.name,
    }));
    const businessHoursOptions = businessHours?.business_hours.map((x) => ({
      value: x.business_hours_id,
      label: x.name,
    }));

    const integrationOptions = integrations?.map((x) => {
      const Icon = integrationIconByType[x.type];
      return {
        value: x.integration_id,
        label: x.name,
        icon: <Icon size={12} />,
      };
    });
    const channelOptions = Object.keys(CHANNEL_VALUE_TO_LABEL).map((x) => ({
      value: x,
      label: CHANNEL_VALUE_TO_LABEL[x],
    }));

    const countryOptions = countryListAllIsoData.map((c) => ({
      label: c.name,
      value: c.code,
    }));

    useEffect(() => {
      if (
        conditionAttribute === 'location' ||
        conditionAttribute === 'global'
      ) {
        const condition =
          RULES_ATTRIBUTES_LABEL_TO_VALUE[
            `${conditionAttribute} ${conditionAttributeSubcategory}`
          ];
        setComparisonOptions(RULES_CONDITON_RADIO_OPTIONS(condition));
        return;
      }
      const condition =
        RULES_ATTRIBUTES_LABEL_TO_VALUE[conditionAttribute] ||
        conditionAttribute;
      setComparisonOptions(RULES_CONDITON_RADIO_OPTIONS(condition));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (conditionCategory === 'business_hours') {
        setComparisonOptions(RULES_CONDITON_RADIO_OPTIONS(conditionCategory));
      }
    }, [conditionCategory]);

    useEffect(() => {
      if (conditionAttribute === 'agent_id') {
        const agent = members?.find((x) => x.user_id === conditionValue);
        if (agent) {
          agent['value'] = agent?.user_id;
        }
        setAgent(pick(agent, ['value', 'name']) as Option);
      }
    }, [conditionAttribute, conditionValue, members]);

    useEffect(() => {
      if (conditionAttribute === 'integration_id') {
        const integration = integrations?.find(
          (x) => x.integration_id === conditionValue
        );
        if (integration) {
          integration['value'] = integration?.integration_id;
        }
        setIntegration(pick(integration, ['value', 'name']) as Option);
      }
    }, [conditionAttribute, conditionValue, integrations]);

    useEffect(() => {
      if (conditionAttribute === 'language' && conditionValue) {
        setLanguage(languageCodes[conditionValue]);
      }
    }, [conditionAttribute, conditionValue]);

    useEffect(() => {
      if (conditionAttribute === 'timezone' && conditionValue) {
        setTimezone(timezoneMaker([conditionValue])[0]);
      }
    }, [conditionAttribute, conditionValue]);

    useEffect(() => {
      const index = comparisonOptions?.findIndex((x) => {
        return x.value === conditionComparison;
      });
      setComparisonInitial(comparisonOptions[index === -1 ? 0 : index]?.value);
    }, [comparisonOptions, conditionComparison]);

    const handleChannelClick = useCallback((data) => {
      setHasClickedAttribute(true);
      setAttribute(data.value);
      setComparisonOptions(RULES_CONDITON_RADIO_OPTIONS(data.value));
    }, []);

    const handleComparisonClick = useCallback((data) => {
      const { value } = data;
      setHasClickedComparison(true);
      setComparison(value);
    }, []);

    const handleLanguageClick = useCallback((data) => {
      const { value } = data;
      setHasClickedLanguage(true);
      setLanguage(value);
    }, []);

    const handleTimezoneClick = useCallback((data) => {
      setHasClickedTimezone(true);
      setTimezone(data);
    }, []);

    const handleCountryClick = useCallback((data) => {
      const { value } = data;
      setHasClickedCountry(true);
      setCountry(value);
    }, []);

    const handleAgentIdClick = useCallback((data) => {
      setHasClickedAgent(true);
      setAgent(data);
    }, []);

    const handleBusinessClick = useCallback((data) => {
      setHasClickedBusiness(true);
      setBusiness(data);
    }, []);

    const handleIntegrationIdClick = useCallback((data) => {
      setHasClickedIntegration(true);
      setIntegration(data);
    }, []);

    const attributeInitial =
      RULES_ATTRIBUTES_VALUE_TO_LABEL[conditionAttribute] || conditionAttribute;

    const attributeSelected =
      RULES_ATTRIBUTES_VALUE_TO_LABEL[attribute] || attribute;

    const comparisonSelected = comparison;

    const needsInput = useMemo(
      () => [
        'city',
        'email',
        'phone',
        'display name',
        'browser',
        'platform',
        'text',
        'tag',
        'global',
      ],
      []
    );
    const needsDropdown = useMemo(
      () => [
        'language',
        'timezone',
        'country',
        'agent',
        'channel',
        'integration',
        'business_hours',
      ],
      []
    );

    const valueForRedux = useCallback(
      (attribute, subCategory, conditionCategory) => {
        if (
          needsInput.includes(attribute) ||
          needsInput.includes(subCategory)
        ) {
          return input;
        }
        if (attribute === 'language') {
          return languageCodesLabelToValue[language];
        }
        if (attribute === 'agent') {
          return agent.value;
        }
        if (attribute === 'channel') {
          return null;
        }
        if (attribute === 'integration') {
          return integration.value;
        }
        if (subCategory === 'country') {
          return country;
        }
        if (attribute === 'timezone') {
          return timezone.value;
        }
        if (conditionCategory === 'business_hours') {
          return business.value;
        }
      },
      [
        needsInput,
        input,
        language,
        agent.value,
        integration.value,
        country,
        timezone.value,
        business,
      ]
    );

    const isKnownUnknown = hasClickedComparison
      ? comparisonSelected === 'known' || comparisonSelected === 'unknown'
      : comparisonSelected === 'known' ||
        comparisonSelected === 'unknown' ||
        comparisonInitial === 'known' ||
        comparisonInitial === 'unknown';

    const comparisonSelector = useCallback(() => {
      return hasClickedComparison ? comparisonSelected : comparisonInitial;
    }, [comparisonInitial, comparisonSelected, hasClickedComparison]);

    const attr = useCallback(
      (conditionCategory) => {
        if (conditionCategory === 'business_hours') {
          return 'business_hours';
        }
        if (conditionCategory === 'channel') {
          return `${conditionCategory}.${
            hasClickedAttribute ? attributeSelected : attributeInitial
          }`;
        }
        if (
          conditionAttribute === 'location' ||
          conditionAttribute === 'global'
        ) {
          return `${conditionCategory}.${conditionAttribute}.${conditionAttributeSubcategory}`;
        }
        return `${conditionCategory}.${
          hasClickedAttribute ? attribute : conditionAttribute
        }`;
      },
      [
        attribute,
        attributeInitial,
        attributeSelected,
        conditionAttribute,
        hasClickedAttribute,
        conditionAttributeSubcategory,
      ]
    );

    const handleButtonClick = useCallback(() => {
      dispatch(
        replaceRuleCondition({
          attribute: attr(conditionCategory),
          comparison: comparisonSelector(),
          value: !isKnownUnknown
            ? valueForRedux(
                hasClickedAttribute ? attributeSelected : attributeInitial,
                conditionAttributeSubcategory,
                conditionCategory
              )
            : null,
          index,
          outerIndex,
        })
      );
      dispatch(isConditionOpen({ index, outerIndex, revertBoolean: false }));
      handleApply();
    }, [
      attributeInitial,
      attributeSelected,
      attr,
      comparisonSelector,
      conditionCategory,
      dispatch,
      handleApply,
      hasClickedAttribute,
      index,
      isKnownUnknown,
      outerIndex,
      valueForRedux,
      conditionAttributeSubcategory,
    ]);

    const onInputChange = (e) => {
      if (conditionAttribute === 'tag' && e.target.value) {
        isValidSlug(e.target.value.trim())
          ? setInputHasError(false)
          : setInputHasError(true);
      }
      e.preventDefault();
      setInput(e.target.value.trim());
    };

    const onKeyUp = useCallback(
      (e) => {
        if (isKeyEnter(e)) {
          e.target.blur();
          handleButtonClick();
        }
      },
      [handleButtonClick]
    );

    useEffect(() => {
      window.addEventListener('keydown', onKeyUp);

      return () => {
        window.removeEventListener('keydown', onKeyUp);
      };
    }, [onKeyUp]);

    const agentDropDownPlaceHolder = useCallback(() => {
      if (hasClickedAgent) {
        return agent.label;
      }
      if (conditionAttribute === 'agent_id' && conditionValue) {
        return members?.find((x) => x.user_id === conditionValue).name;
      }
      return t('rules.agent_placeholder');
    }, [
      agent.label,
      conditionAttribute,
      conditionValue,
      hasClickedAgent,
      members,
      t,
    ]);

    const businessPlaceHolder = useCallback(() => {
      if (hasClickedBusiness) {
        return business.label;
      }
      if (conditionAttribute === 'business_id' && conditionValue) {
        return businessHours?.business_hours?.find(
          (x) => x.business_hours_id === conditionValue
        ).name;
      }
      return t('rules.business_hours_placeholder');
    }, [
      business.label,
      businessHours?.business_hours,
      conditionAttribute,
      conditionValue,
      hasClickedBusiness,
      t,
    ]);

    const integrationDropDownPlaceHolder = useCallback(() => {
      if (hasClickedChannel) {
        return integration.label;
      }
      if (conditionAttribute === 'integration_id' && conditionValue) {
        return integrations?.find((x) => x.integration_id === conditionValue)
          ?.name;
      }
      return 'Integration...';
    }, [
      conditionAttribute,
      conditionValue,
      hasClickedChannel,
      integration.label,
      integrations,
    ]);

    const isDirty =
      comparison === 'unknown' ||
      comparison === 'known' ||
      hasClickedAgent ||
      hasClickedAttribute ||
      (!!input && !inputHasError) ||
      hasClickedChannel ||
      hasClickedLanguage ||
      hasClickedCountry ||
      hasClickedTimezone ||
      hasClickedBusiness;

    const languageOptions = useCallback(() => {
      return Object.values(languageCodes).map((x) => ({
        value: x,
        label: x,
      }));
    }, []);

    const timezoneOptions = useMemo(() => {
      const times = timezoneMaker(timezones);
      return times.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
    }, []);

    const secondField = (attribute, category, subCategory) => {
      if (needsInput.includes(attribute) || needsInput.includes(subCategory)) {
        return (
          <div className={styles.input}>
            <InputBase
              placeholder={t('common.type_value')}
              onChange={onInputChange}
              onKeyUp={onKeyUp}
              sx={muiStyles.root}
              defaultValue={conditionValue ? conditionValue : null}
              autoFocus
              error={inputHasError}
            />
            {inputHasError && (
              <p className={styles.errorMessage}>{t('rules.tags_rules')}</p>
            )}
          </div>
        );
      }

      if (
        needsDropdown.includes(attribute) ||
        needsDropdown.includes(category) ||
        needsDropdown.includes(subCategory)
      ) {
        if (attribute === 'language') {
          const value = hasClickedLanguage
            ? language
            : languageCodes[conditionValue] || 'English';
          return (
            <DropdownList
              optionClick={handleLanguageClick}
              options={languageOptions()}
              size="medium"
              searchBar
              selected={value}
            >
              {value}
            </DropdownList>
          );
        }

        if (category === 'business_hours') {
          return (
            <DropdownList
              optionClick={handleBusinessClick}
              options={businessHoursOptions}
              size="medium"
              selected={businessPlaceHolder()}
            >
              {businessPlaceHolder()}
            </DropdownList>
          );
        }

        if (attribute === 'timezone') {
          return (
            <DropdownList
              optionClick={handleTimezoneClick}
              options={timezoneOptions}
              size="medium"
              searchBar
              selected={timezone.label}
            >
              {timezone.label}
            </DropdownList>
          );
        }

        if (attribute === 'agent') {
          return (
            <DropdownList
              optionClick={handleAgentIdClick}
              options={agentOptions}
              size="medium"
              selected={agentDropDownPlaceHolder()}
            >
              {agentDropDownPlaceHolder()}
            </DropdownList>
          );
        }

        if (attribute === 'integration') {
          return (
            <DropdownList
              optionClick={handleIntegrationIdClick}
              options={integrationOptions}
              size="small"
              selected={integrationDropDownPlaceHolder()}
            >
              {integrationDropDownPlaceHolder()}
            </DropdownList>
          );
        }

        if (category === 'channel') {
          return (
            <DropdownList
              optionClick={handleChannelClick}
              options={channelOptions}
              size="small"
              selected={
                hasClickedAttribute ? attributeSelected : attributeInitial
              }
            >
              {hasClickedAttribute
                ? CHANNEL_VALUE_TO_LABEL[attributeSelected]
                : CHANNEL_VALUE_TO_LABEL[attributeInitial] ||
                  t('rules.channel_placeholder')}
            </DropdownList>
          );
        }

        if (subCategory === 'country') {
          return (
            <DropdownList
              optionClick={handleCountryClick}
              options={countryOptions}
              size="small"
              searchBar
              selected={
                hasClickedCountry
                  ? COUNTRIES_VALUE_TO_LABEL[country]
                  : COUNTRIES_VALUE_TO_LABEL[conditionValue]
              }
            >
              {hasClickedCountry
                ? COUNTRIES_VALUE_TO_LABEL[country]
                : COUNTRIES_VALUE_TO_LABEL[conditionValue] ||
                  t('rules.country_placeholder')}
            </DropdownList>
          );
        }
      }
    };

    const titleGenerator = useCallback(
      (
        conditionAttribute,
        conditionCategory,
        conditionAttributeSubcategory
      ) => {
        if (
          conditionAttribute === 'location' ||
          conditionAttribute === 'global'
        ) {
          return t(
            `rules.${RULES_ATTRIBUTES_VALUE_TO_LABEL[conditionAttributeSubcategory]}`
          );
        }
        if (conditionAttribute) {
          return t(
            `rules.${RULES_ATTRIBUTES_VALUE_TO_LABEL[conditionAttribute]}`
          );
        }

        if (conditionCategory) {
          return t(`rules.${conditionCategory}`);
        }
      },
      [t]
    );

    return (
      <ConditionsModal
        title={titleGenerator(
          conditionAttribute,
          conditionCategory,
          conditionAttributeSubcategory
        )}
        buttonText={conditionButtonText}
        handleSubmit={handleButtonClick}
        ref={ref}
        isDirty={conditionAttribute === 'language' ? false : !isDirty}
      >
        <div className={styles.wrapper}>
          <DropdownList
            optionClick={handleComparisonClick}
            options={comparisonOptions}
            size="small"
            // renderValue
            translationPrefix="rules."
            selected={
              hasClickedComparison ? comparisonSelected : comparisonInitial
            }
          >
            {hasClickedComparison
              ? t(`rules.${comparisonSelected}`)
              : t(`rules.${comparisonInitial}`)}
          </DropdownList>

          {!isKnownUnknown &&
            secondField(
              hasClickedAttribute ? attributeSelected : attributeInitial,
              conditionCategory,
              conditionAttributeSubcategory
            )}
        </div>
      </ConditionsModal>
    );
  }
);

ConditionConfigurationModal.displayName = 'ConditionConfigurationModal';

export default ConditionConfigurationModal;
