import { createSlice } from '@reduxjs/toolkit';

import {
  ACTIONS_VALUE_TO_LABEL,
  TRIGGERS_VALUE_TO_LABEL,
} from '@/components/organisms/Condition/constants';
import { ActionType, Rule } from '@/modules/rules/model';

const defaultState: Partial<Rule> = {
  name: '',
  rule_id: '',
  description: '',
  status: 'inactive',
  triggers: [],
  options: [],
  condition: {
    operator: 'or',
    conditions: [],
    isConditionOpen: false,
    isError: false,
    isHover: false,
  },
  actions: [],
  actionsOptions: [],
  dirty: false,
};

const slice = createSlice({
  name: 'rule',
  initialState: defaultState,
  reducers: {
    setDraftRule: (state, action) => {
      state.options = TRIGGERS_VALUE_TO_LABEL.map((x) => ({
        type: x,
      }));

      state.actionsOptions = ACTIONS_VALUE_TO_LABEL.map((x) => ({
        type: x,
      }));

      Object.assign(state, action.payload);
    },

    // Triggers
    addTrigger: (state, action) => {
      const { type, isOpen } = action.payload;
      state.dirty = true;
      state.triggers = [...state.triggers, { type, isOpen }];
    },
    removeTrigger: (state, action) => {
      const { type } = action.payload;
      state.dirty = true;
      const index = state.triggers.findIndex((x) => x['type'] === type);
      state.triggers = state.triggers.filter((x, i) => i !== index);
    },
    replaceTrigger: (state, action) => {
      const { type, index, delay } = action.payload;
      if (type === 'awaiting_user_reply' || type === 'awaiting_agent_reply') {
        state.triggers[index] = { type, delay_in_seconds: delay };
        state.dirty = true;
        return;
      }
      state.triggers[index] = { type };
      state.dirty = true;
    },
    revertIsTriggerOpen: (state, action) => {
      const { index, isOpenState } = action.payload;
      state.triggers[index].isOpen = isOpenState;
      if (!isOpenState) {
        state.triggers[index].isOpen = isOpenState;
      } else {
        state.triggers.forEach((trigger, idx) => {
          if (index === idx) {
            return (trigger['isOpen'] = isOpenState);
          }
          return (trigger['isOpen'] = !isOpenState);
        });
      }
    },
    isTriggerError: (state, action) => {
      const { index, errorState } = action.payload;
      if (index + 1 > state.triggers.length) {
        return;
      } else {
        state.triggers[index].isError = errorState;
      }
    },

    // Actions
    addAction: (state, action) => {
      const { type, isOpen } = action.payload;
      state.dirty = true;
      state.actions = [...state.actions, { type, isOpen }];
    },
    replaceAction: (state, action) => {
      const {
        brain_parent_id,
        brain_version,
        index,
        type,
        tag,
        bundle_id,
        department_id,
        collection_id,
      } = action.payload;
      state.dirty = true;
      if (type === ActionType.ADD_TAG) {
        state.actions[index] = {
          type,
          tag,
        };
        return;
      }
      if (type === ActionType.APPLY_BUNDLE) {
        state.actions[index] = {
          type,
          bundle_id,
        };
        return;
      }
      if (type === ActionType.ASSIGN_COLLECTION) {
        state.actions[index] = {
          type,
          collection_id,
        };
        return;
      }
      if (type === ActionType.ASSIGN_DEPARTMENT) {
        state.actions[index] = {
          type,
          department_id,
        };
        return;
      }
      state.actions[index] = {
        brain_parent_id,
        brain_version,
        type,
      };
    },
    removeAction: (state, action) => {
      state.dirty = true;
      state.actions = state.actions.filter((_, i) => i !== action.payload);
    },
    revertIsActionOpen: (state, action) => {
      const { index, isOpenState } = action.payload;
      state.actions[index].isOpen = isOpenState;
      if (!isOpenState) {
        state.actions[index].isOpen = isOpenState;
      } else {
        state.actions.forEach((action, idx) => {
          if (index === idx) {
            return (action['isOpen'] = isOpenState);
          }
          return (action['isOpen'] = !isOpenState);
        });
      }
    },
    isActionError: (state, action) => {
      const { index, errorState } = action.payload;
      if (index + 1 > state.actions.length) {
        return;
      } else {
        state.actions[index].isError = errorState;
      }
    },
    setCondition: (state, action) => {
      state.condition = action.payload;
    },
    // utils
    revertDirty: (state) => {
      state.dirty = false;
    },
    resetDefaultRule: () => defaultState,
  },
});

export const {
  addTrigger,
  removeTrigger,
  replaceTrigger,
  addAction,
  replaceAction,
  setDraftRule,
  revertDirty,
  removeAction,
  revertIsActionOpen,
  isActionError,
  revertIsTriggerOpen,
  isTriggerError,
  resetDefaultRule,
  setCondition,
} = slice.actions;

export default slice.reducer;
