import { useContext, useEffect, useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SocketContext } from '@/contexts/rtm';
import { useAccount } from '@/hooks/useAccount';
import useDesktopNotifications from '@/hooks/useDesktopNotifications';
import useMessageSound from '@/hooks/useMessageSound';
import useUser from '@/hooks/useUser';
import { useUserRoles } from '@/hooks/useUserRoles';
import { ChatState, Conversation } from '@/models/chat';
import { actions } from '@/models/permissions';
import { ServerToClientEvents, SocketEvent } from '@/models/rtm';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectSessionId } from '@/redux/session/selectors';
import messageSound from '@/util/message.mp3';

import {
  onConversationCreated,
  onConversationHandover,
  onConversationReopened,
  onConversationResolved,
  onConversationUpdated,
  onVisitorActivity,
} from './useConversations';

export const useRtmConversationsNew = (deskId: string) => {
  const { t } = useTranslation();
  const { showNotifications } = useDesktopNotifications();
  const { agents } = useAccount();
  const { play } = useMessageSound(messageSound);
  const { socket } = useContext(SocketContext);
  const { user } = useUser();
  const { slug } = useAccount();
  const sessionId = useSelector(selectSessionId);
  const queryClient = useQueryClient();

  // avoid playing sound multiple times. this occurs on roundrobin and balanced modes (unnasigned to handover and `updated` events)
  const throttledPlay = throttle(
    ({ url, text }) => {
      play();
      showNotifications({
        url,
        text,
      });
    },
    400,
    { leading: false, trailing: true }
  );

  const canReadDepartmentsOthers = useSelector((state: RootState) =>
    getPermissions(state, 'conversations.read.departments', actions.OTHERS)
  );

  const canReadConversationsOthers = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.READ_OTHERS)
  );

  const { getUserDepartments } = useUserRoles(user?.user_id);
  const userId = user?.user_id;

  /* If the user can't read other departments' conversations and the conversation
      belongs to another department, don't update the cache.
      Also if the user can't read other agents' conversations and the conversation belongs to another agent
  */
  const preventConversationUpdate = useCallback(
    (conversation: Conversation) => {
      const userDepartments = getUserDepartments(conversation?.desk_id);

      const conversationHasDepartment = conversation?.department_id;
      const belongsToDepartment = userDepartments?.find(
        (d) => d.department_id === conversation.department_id
      );
      if (
        !canReadConversationsOthers &&
        conversation.assignee_agent_id &&
        conversation.assignee_agent_id !== userId
      ) {
        return true;
      }
      if (
        conversationHasDepartment &&
        !canReadDepartmentsOthers &&
        !belongsToDepartment &&
        conversation.assignee_agent_id !== userId
      ) {
        return true;
      }
      return false;
    },
    [
      canReadConversationsOthers,
      canReadDepartmentsOthers,
      getUserDepartments,
      userId,
    ]
  );

  const handleCreated: ServerToClientEvents[SocketEvent.conversations_created] =
    useCallback(
      ({ data: conversation }) => {
        onConversationCreated({
          queryClient,
          conversation,
          deskId,
          shouldPreventAddToCache: preventConversationUpdate(conversation),
        });
      },
      [deskId, preventConversationUpdate, queryClient]
    );

  const handleUpdated: ServerToClientEvents[SocketEvent.conversations_updated] =
    useCallback(
      ({ data: conversation }) => {
        if (
          conversation.assignee_agent_id === userId &&
          conversation.session_id !== sessionId &&
          conversation.agent_unread_count > 0 &&
          conversation.status === ChatState.OPEN &&
          !preventConversationUpdate(conversation)
        ) {
          throttledPlay({
            url: `/${slug}/chats/${deskId}/conversations/${conversation.session_id}`,
            text: t('conversation.you_have_a_new_message'),
          });
        }
        onConversationUpdated({
          queryClient,
          conversation,
          deskId,
          agents,
          shouldPreventAddToCache: preventConversationUpdate(conversation),
        });
      },
      [
        agents,
        deskId,
        preventConversationUpdate,
        queryClient,
        sessionId,
        slug,
        t,
        throttledPlay,
        userId,
      ]
    );

  const handleHandover: ServerToClientEvents[SocketEvent.conversations_handover] =
    useCallback(
      ({ data: conversation }) => {
        if (conversation.desk_id !== deskId) {
          return;
        }

        const isAssignedToMe = conversation.assignee_agent_id === userId;
        const isUnassigned =
          !conversation.assignee_agent_id && !conversation.assignee_brain_id;
        if (!preventConversationUpdate(conversation)) {
          if (isAssignedToMe || isUnassigned) {
            throttledPlay({
              url: `/${slug}/chats/${deskId}/conversations/${conversation.session_id}`,
              text: t('conversation.user_asked_for_agent'),
            });
          } else {
            // is not assigned to me or unassigned so cancel the play
            throttledPlay.cancel();
          }
        } else {
          throttledPlay.cancel();
        }
        onConversationHandover({
          queryClient,
          conversation,
          deskId,
          agents,
          shouldPreventAddToCache: preventConversationUpdate(conversation),
        });
      },
      [
        deskId,
        preventConversationUpdate,
        userId,
        queryClient,
        agents,
        throttledPlay,
        slug,
        t,
      ]
    );

  const handleResolved: ServerToClientEvents[SocketEvent.conversations_resolved] =
    useCallback(
      ({ data: conversation }) => {
        onConversationResolved({
          queryClient,
          conversation,
          deskId,
          agents,
        });
      },
      [agents, deskId, queryClient]
    );

  const handleReopened: ServerToClientEvents[SocketEvent.conversations_reopened] =
    useCallback(
      ({ data: conversation }) => {
        onConversationReopened(queryClient, conversation, deskId, agents);
      },
      [deskId, queryClient, agents]
    );

  const handleActivity: ServerToClientEvents[SocketEvent.conversations_activity] =
    useCallback(
      ({ data: activity }) => {
        onVisitorActivity(queryClient, activity, deskId, agents);
      },
      [agents, deskId, queryClient]
    );

  const handleExpired: ServerToClientEvents[SocketEvent.conversations_expired] =
    useCallback(
      ({ data: conversation }) => {
        onConversationResolved({
          queryClient,
          conversation,
          deskId,
          agents,
        });
      },
      [agents, deskId, queryClient]
    );

  useEffect(() => {
    socket?.on(SocketEvent.conversations_updated, handleUpdated);
    socket?.on(SocketEvent.conversations_resolved, handleResolved);
    socket?.on(SocketEvent.conversations_created, handleCreated);
    socket?.on(SocketEvent.conversations_handover, handleHandover);
    socket?.on(SocketEvent.conversations_reopened, handleReopened);
    socket?.on(SocketEvent.conversations_activity, handleActivity);
    socket?.on(SocketEvent.conversations_expired, handleExpired);
    return () => {
      socket?.off(SocketEvent.conversations_created, handleCreated);
      socket?.off(SocketEvent.conversations_updated, handleUpdated);
      socket?.off(SocketEvent.conversations_resolved, handleResolved);
      socket?.off(SocketEvent.conversations_reopened, handleReopened);
      socket?.off(SocketEvent.conversations_handover, handleHandover);
      socket?.off(SocketEvent.conversations_activity, handleActivity);
      socket?.off(SocketEvent.conversations_expired, handleExpired);
    };
  }, [
    handleCreated,
    handleResolved,
    handleUpdated,
    handleReopened,
    handleHandover,
    handleActivity,
    socket,
    handleExpired,
  ]);
};
