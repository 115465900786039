import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import { selectBrainId } from '@/redux/session/selectors';

// This is a helper hook to find reminders for dialog
// in order to apply some rendering logic
export const useDialogReminder = (dialogId: string) => {
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { dialog } = useDialogs(brainId, dialogId);
  const [hasReminders, setHasreminders] = useState(false);

  const dialogNodeIds = dialog?.nodes?.map((node) => node.node_id);
  const brainReminders = brain?.guidelines?.reminders || [];
  const nodeIdsWithReminders = brainReminders.map(
    (reminder) => reminder.node_id
  );

  useEffect(() => {
    if (brainReminders.length === 0) {
      setHasreminders(false);
    } else {
      const nodeHasReminder = dialogNodeIds?.some((id) =>
        nodeIdsWithReminders.includes(id)
      );
      setHasreminders(!!nodeHasReminder);
    }
  }, [brainReminders.length, dialogNodeIds, nodeIdsWithReminders]);

  return {
    hasReminders,
  };
};
