import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { rulesEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { Rule, Rules } from '@/modules/rules/model';
import { revertDirty } from '@/modules/rules/redux/actions';
import { selectRule } from '@/modules/rules/redux/selectors';
import { selectCondition } from '@/redux/condition/selectors';

export const API = Object.freeze({
  listRules: async (deskId: string): Promise<Rules> =>
    callGet(endpoints.rules(deskId)),

  getRule: async (deskId: string, ruleId: string): Promise<Rule> =>
    callGet(endpoints.rule(deskId, ruleId)),

  addRule: async (deskId: string, newRule: Partial<Rule>): Promise<Rule> =>
    callPost(endpoints.rules(deskId), newRule),

  updateRule: async (deskId: string, rule: Partial<Rule>): Promise<Rule> =>
    callPut(endpoints.rule(deskId, rule.rule_id), rule),

  reorderRule: async (
    deskId: string,
    ruleId: string,
    position: number
  ): Promise<Rule> => callPut(endpoints.reorder(deskId, ruleId), { position }),

  deleteRule: async (deskId: string, rule_id): Promise<Rule> =>
    callDelete(endpoints.rule(deskId, rule_id)),
});

export type ReorderProps = { rule_id: string; position: number };

export const onRuleUpdated = (
  queryClient: QueryClient,
  rule: Rule,
  deskId: string
) => {
  queryClient.setQueryData<Rule>(
    [endpoints.rule(deskId, rule.rule_id)],
    (prev: Rule) => ({ ...prev, ...rule })
  );

  const queryKey = [endpoints.rules(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Rules>(queryKey, (prev: Rules) => ({
      rules: (prev?.rules || []).map((item) =>
        item.rule_id === rule.rule_id ? { ...item, ...rule } : item
      ),
    }));
  }
};
export const onRuleCreated = (
  queryClient: QueryClient,
  rule: Rule,
  deskId: string
) => {
  const queryKey = [endpoints.rules(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Rules>(queryKey, (prev) => ({
      rules: [
        ...(prev?.rules || []).filter((item) => item.rule_id !== rule.rule_id),
        rule,
      ],
    }));
  }
};

export const onRuleReorder = (
  queryClient: QueryClient,
  deskId: string,
  rule: Rule,
  rules: Rules
) => {
  let prevPosition: number;
  const newPosition = rule.position;

  queryClient.setQueryData<Rule>(
    [endpoints.rule(deskId, rule.rule_id)],
    (prev: Rule) => {
      prevPosition = prev?.position;
      return { ...prev, ...rule };
    }
  );

  let start: number;
  let end: number;
  let step: number;

  if (prevPosition < newPosition) {
    start = prevPosition;
    end = newPosition;
    step = -1;
  } else {
    start = newPosition;
    end = prevPosition;
    step = 1;
  }

  rules?.rules?.forEach((r) => {
    queryClient.setQueryData<Rule>(
      [endpoints.rule(deskId, r.rule_id)],
      (prev: Rule) => {
        if (prev?.rule_id === rule.rule_id) {
          return prev;
        }
        if (prev?.position >= start && prev?.position <= end) {
          return { ...prev, position: prev.position + step };
        }
        return prev;
      }
    );
  });

  queryClient.setQueryData<Rules>([endpoints.rules(deskId)], (prev: Rules) => ({
    rules: (prev?.rules || []).map((item) => {
      if (item.position === prevPosition) {
        return rule;
      }
      if (item.position >= start && item.position <= end) {
        return { ...item, position: item.position + step };
      }
      return item;
    }),
  }));
};

export const onRuleRemoved = (
  queryClient: QueryClient,
  deskId: string,
  rule_id: string
) => {
  const queryKey = [endpoints.rules(deskId)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Rules>(queryKey, (prev: Rules) => {
      const prevPosition = (prev?.rules || []).find(
        (rule) => rule.rule_id === rule_id
      )?.position;
      return {
        rules: (prev?.rules || [])
          .filter((rule) => rule.rule_id !== rule_id)
          .map((rule) =>
            rule.position < prevPosition
              ? rule
              : { ...rule, position: rule.position - 1 }
          ),
      };
    });
    const rules = queryClient.getQueryData<Rules>(queryKey);
    rules.rules.forEach((rule) =>
      queryClient.setQueryData<Rule>(
        [endpoints.rule(deskId, rule.rule_id)],
        (prev: Rule) => ({ ...prev, position: rule.position })
      )
    );
  }
  queryClient.removeQueries({ queryKey: [endpoints.rule(deskId, rule_id)] });
};

export const useRules = (deskId: string, ruleId?: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const draftRule = useSelector(selectRule);
  const condition = useSelector(selectCondition);

  const { data: rules, status: listStatus } = useQuery<Rules, Error>({
    queryKey: [endpoints.rules(deskId)],
    queryFn: () => API.listRules(deskId),
    enabled: !!deskId,
  });

  const {
    data: rule,
    status: getStatus,
    isLoading: isRuleLoading,
  } = useQuery<Rule, Error>({
    queryKey: [endpoints.rule(deskId, ruleId)],
    queryFn: () => API.getRule(deskId, ruleId),
    enabled: !!ruleId && !!deskId && ruleId !== 'draft',
  });

  const { mutate: updateRule, status: updateStatus } = useMutation<
    Rule,
    Error,
    Partial<Rule>
  >({
    mutationFn: (rule) => API.updateRule(deskId, rule),
    onSuccess: (resp) => {
      dispatch(revertDirty());
      onRuleUpdated(queryClient, resp, deskId);
      dispatch(
        addTemporalToast('success', t('rules.rule_updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: addRule, status: createStatus } = useMutation<
    Rule,
    Error,
    Partial<Rule>
  >({
    mutationFn: (newRule?: Partial<Rule>) => {
      return API.addRule(deskId, {
        ...omit(newRule ?? draftRule, ['options']),
        condition,
      });
    },
    onSuccess: (resp) => {
      dispatch(revertDirty());

      onRuleCreated(queryClient, resp, deskId);
      dispatch(
        addTemporalToast('success', t('rules.rule_created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteRule, status: deleteStatus } = useMutation<
    Rule,
    Error,
    string
  >({
    mutationFn: (id) => API.deleteRule(deskId, id),
    onSuccess: (resp) => {
      onRuleRemoved(queryClient, deskId, resp.rule_id);
      dispatch(addTemporalToast('success', t('rules.rule_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: reorderRule, status: reorderStatus } = useMutation<
    Rule,
    Error,
    ReorderProps
  >({
    mutationFn: (params) =>
      API.reorderRule(deskId, params.rule_id, params.position),
    onSuccess: (resp) => {
      onRuleReorder(queryClient, deskId, resp, rules);
      dispatch(
        addTemporalToast('success', t('rules.rule_reordered', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    rules: {
      ...rules,
      rules: rules?.rules?.sort((a, b) => a.position - b.position),
    },
    isRuleLoading,
    rule,
    getStatus,
    listStatus,
    updateRule,
    updateStatus,
    addRule,
    createStatus,
    deleteRule,
    deleteStatus,
    reorderRule,
    reorderStatus,
  };
};
