import { forwardRef, useCallback, useEffect, useState } from 'react';

import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import useVersions from '@/hooks/useVersions';
import { Brain } from '@/models/brain';
import { Version } from '@/models/version';
import RuleActionsModal from '@/modules/rules/components/RuleActionsModal/RuleActionsModal';
import { useRules } from '@/modules/rules/hooks/useRules';
import { selectDeskId, selectRuleId } from '@/redux/session/selectors';

import BrainDescription from './BrainDescription/BrainDescription';
import RuleActionTable from './RuleActionTable/RuleActionTable';

export interface BrainVersion {
  brain_parent_id: string;
  brain_version: number;
}

interface Props {
  initiallySelected?: string;
  callbackFn?: () => void;
  disconnectButton?: boolean;
  renderOverElement?: boolean;
  version?: number;
  onSubmit: (data: BrainVersion) => void;
}

export type BrainWithVersion = Brain & {
  versions: { value: number; label: string }[];
  versionCount: number;
};

// Sort brains by last_trained date, with the selected brain at the top
function sortBrains(array: Brain[], brainId: string) {
  return array.sort((a, b) => {
    // Check if either object has the specific brain_id
    if (a.brain_id === brainId) return -1;
    if (b.brain_id === brainId) return 1;

    // If neither object is the specific one, sort by last_trained date
    return (
      new Date(b.last_trained).getTime() - new Date(a.last_trained).getTime()
    );
  });
}

const ActionConfiguration = forwardRef<HTMLDivElement, Props>(
  (
    {
      initiallySelected,
      onSubmit,
      callbackFn,
      renderOverElement = false,
      version,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [selectedBrain, setSelectedBrain] = useState(initiallySelected);
    const [selectedVersion, setSelectedVersion] = useState(version);
    const deskId = useSelector(selectDeskId);
    const ruleId = useSelector(selectRuleId);
    const { brains } = useBrains();
    const { versions, getStatus } = useVersions(selectedBrain);
    const { rules } = useRules(deskId);
    const selectedRules = rules?.rules?.filter(
      (r) =>
        r.actions.some(
          (a) =>
            a.brain_parent_id === selectedBrain &&
            a.brain_version === selectedVersion
        ) && r.rule_id !== ruleId
    );

    useEffect(() => {
      if (selectedBrain && versions && isNil(selectedVersion)) {
        const defaultVersion =
          versions.find((v) => v.version === 0) || versions[0];
        setSelectedVersion(defaultVersion?.version);
      }
    }, [selectedBrain, selectedVersion, versions]);

    const brainFromList = brains?.find((b) => b.brain_id === selectedBrain);
    const versionFromList = versions?.find(
      (v) => v.version === selectedVersion
    );

    const handleApply = useCallback(() => {
      const newBrain: BrainVersion = {
        brain_parent_id: selectedBrain,
        brain_version: Number(selectedVersion),
      };
      onSubmit(newBrain);
    }, [onSubmit, selectedBrain, selectedVersion]);

    const handleBrainChange = useCallback(
      (brain, version_id, saveChanges) => {
        const versionId = !isNil(version_id) ? Number(version_id) : null;
        setSelectedBrain(brain.brain_id);
        setSelectedVersion(versionId);
        if (saveChanges) {
          handleApply();
        }
      },
      [handleApply]
    );

    const tableRows =
      sortBrains(brains || [], selectedBrain).map((b) => {
        let brainVersions = [{ value: 0, label: t('common.draft') }];

        if (b.brain_id === selectedBrain && versions?.length > 0) {
          brainVersions = versions
            .filter((v: Version) => v.status === 'available')
            .map((v) => ({
              value: v.version,
              label: v.version === 0 ? t('common.draft') : `v${v.version}`,
            }))
            .sort((a, b) =>
              a.label === t('common.draft') ? -1 : b.value - a.value
            );
        }
        return {
          ...b,
          versions: brainVersions,
          versionCount: b.version_count,
        } as unknown as BrainWithVersion;
      }) || [];

    return (
      <RuleActionsModal
        handleSubmit={handleApply}
        ref={ref}
        callbackFn={callbackFn}
        renderOverElement={renderOverElement}
        sideComponent={
          selectedBrain && (
            <BrainDescription
              brain={brainFromList}
              version={versionFromList}
              rules={selectedRules}
            />
          )
        }
      >
        <RuleActionTable
          data={tableRows}
          onSelectionClick={handleBrainChange}
          getStatus={getStatus}
        />
      </RuleActionsModal>
    );
  }
);

ActionConfiguration.displayName = 'ActionConfiguration';

export default ActionConfiguration;
