import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePageVisibility } from 'react-page-visibility';
import { useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import useRtmMessages from '@/hooks/rtm/useRtmMessages';
import useUser from '@/hooks/useUser';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import { ChatContext } from './context';
import ChatBox from './MessageArea/ChatBox/ChatBox';
import MessageArea from './MessageArea/MessageArea';
import { useConversations } from '../../hooks/useConversations';
import { selectView } from '../../redux/selectors';
import ConversationHeader from '../ConversationHeader/ConversationHeader';
import { RightSidebar } from '../RightSidebar/RightSidebar';

import styles from './Conversation.module.scss';

export default function Conversation() {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const isVisible = usePageVisibility();
  const { user } = useUser();
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const [chat, setChat] = useState<{ [key: string]: string }>({});
  const { selectedAgent } = useSelector(selectView);
  const { conversation, getStatus, updateRead } = useConversations({
    deskId,
    sessionId,
  });

  useRtmMessages(deskId, sessionId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.CONVERSATION);
  }, []);

  useEffect(() => {
    const isRelevant =
      conversation?.assignee_agent_id === user?.user_id ||
      !conversation?.state?.active;
    const hasUnreadMessages = conversation?.agent_unread_count > 0;

    if (isRelevant && hasUnreadMessages && isVisible) {
      updateRead();
    }
  }, [
    conversation?.state?.active,
    conversation?.agent_unread_count,
    conversation?.assignee_agent_id,
    isVisible,
    selectedAgent,
    updateRead,
    user?.user_id,
  ]);

  const contextValue = useMemo(() => ({ chat, setChat }), [chat]);

  return (
    <>
      <div className={styles.conversation} ref={nodeRef}>
        <div className={styles.deskWrapper}>
          <ConversationHeader />

          {!canWrite && (
            <Banner variant="neutral" relativePosition centered padding="small">
              {t('permissions.read_only')}
            </Banner>
          )}
          <div
            className={cn(styles.chatWrapper, {
              [styles.readOnly]: !canWrite,
            })}
          >
            <MessageArea isLoading={getStatus === 'pending'} />
            <ChatContext.Provider value={contextValue}>
              <ChatBox />
            </ChatContext.Provider>
          </div>
        </div>
      </div>
      <RightSidebar />
    </>
  );
}
