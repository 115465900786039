import { useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import { MenuItemsType } from '@/hooks/useViews';

import { Menu } from './Menu';
import { NavItem } from './NavItem/NavItem';
import { MenuItem } from '../../models';
import { selectView } from '../../redux/selectors';
import { trimText } from '../../utils';
import { CustomDetails } from '../CustomDetails/CustomDetails';

import styles from './NavList.module.scss';

const MAX_NAME_LENGTH = 15;

const Option = ({
  hasPermission,
  showListMenu,
  items,
  pinnedItems,
  onMoreClick,
}) => {
  if (!hasPermission) {
    return null;
  }
  if (showListMenu && items) {
    return (
      <Menu items={items} pinnedItems={pinnedItems}>
        <div className={styles.plusButton} data-testid={'hover-option'}>
          <PlusCircle color={'var(--icon-default-blue)'} />
        </div>
      </Menu>
    );
  } else if (onMoreClick) {
    return (
      <IconButton className={styles.plusButton} onClick={onMoreClick}>
        <PlusCircle color={'var(--icon-default-blue)'} />
      </IconButton>
    );
  } else {
    return null;
  }
};

type Props = {
  title: string;
  onMoreClick?: () => void;
  pinnedItems?: MenuItem[] | MenuItemsType;
  items?: MenuItem[];
  hasPermission?: boolean;
  keepOpen?: boolean;
  open?: boolean;
};

export const NavList = ({
  title,
  pinnedItems,
  items,
  hasPermission,
  onMoreClick,
  keepOpen = false,
  open = false,
}: Props) => {
  const {
    selectedAgent,
    selectedDepartment,
    title: viewTitle,
    selectedBrain,
    selectedView,
  } = useSelector(selectView);

  const showListMenu = Boolean(items?.length);

  const { t } = useTranslation();

  const selected = useCallback(
    (id: string) => {
      if (viewTitle) {
        const converted = viewTitle === 'me' ? 'inbox' : viewTitle;
        return converted === id;
      }

      if (selectedAgent) {
        return selectedAgent === id;
      }

      if (selectedBrain) {
        return selectedBrain === id;
      }

      if (selectedDepartment) {
        return selectedDepartment === id;
      }

      if (selectedView) {
        return selectedView === id;
      }
    },
    [selectedAgent, selectedBrain, selectedDepartment, selectedView, viewTitle]
  );

  if (!items?.length && !pinnedItems?.length && !onMoreClick) {
    return null;
  }
  return (
    <CustomDetails
      keepOpen={keepOpen}
      open={open}
      title={
        <div className={styles.title}>
          <Typography variant="label-caps-large">{title}</Typography>
        </div>
      }
      option={
        <Option
          hasPermission={hasPermission}
          items={items}
          pinnedItems={pinnedItems}
          onMoreClick={onMoreClick}
          showListMenu={showListMenu}
        />
      }
      showBorder={false}
    >
      <List>
        {pinnedItems?.map((item) => {
          return (
            <NavItem
              hasNotification={item?.hasNotification}
              text={trimText(item?.text, MAX_NAME_LENGTH)}
              icon={item?.icon}
              onClick={item?.onClick}
              sum={item?.sum}
              key={item?.id}
              selected={selected(item?.id)}
              options={item?.options}
              disabled={item?.disabled}
              type={item?.type}
            />
          );
        })}
        {pinnedItems?.length === 0 && (
          <ListItem>
            <Typography
              color="var(--text-disabled-gray)"
              className={styles.emptyList}
            >
              {t(`environments.human_chat_empty_dropdown`, {
                title,
              })}
            </Typography>
          </ListItem>
        )}
      </List>
    </CustomDetails>
  );
};
