import Brand from '@/components/atoms/Icons/Navigation/Brand';

import { Anthropic } from '../../icons/Anthropic';
import { Azure } from '../../icons/Azure';
import { DeepSeek } from '../../icons/DeepSeek';
import { Gemini } from '../../icons/Gemini';
import { OpenAI } from '../../icons/OpenAI';
import { LanguageModel, ProviderName } from '../../types';

export const modelIconResolver = (
  provider: ProviderName,
  size = 24
): JSX.Element => {
  switch (provider) {
    case 'openai':
      return <OpenAI size={size} />;
    case 'anthropic':
      return <Anthropic size={size} />;
    case 'azure':
      return <Azure size={size} />;
    case 'gemini':
      return <Gemini size={size} />;
    case 'deepseek':
      return <DeepSeek size={size} />;
  }
};

export const modelNameMapper = (provider: ProviderName): string => {
  switch (provider) {
    case 'openai':
      return 'OpenAI';
    case 'anthropic':
      return 'Anthropic';
    case 'azure':
      return 'Azure OpenAI';
    case 'gemini':
      return 'Gemini';
    case 'deepseek':
      return 'DeepSeek';
  }
};

export const providerNameMapper = (provider: ProviderName): string => {
  switch (provider) {
    case 'openai':
      return 'OpenAI';
    case 'anthropic':
      return 'Anthropic';
    case 'azure':
      return 'Azure';
    case 'gemini':
      return 'Gemini';
    case 'moveo':
      return 'Moveo.AI';
    case 'deepseek':
      return 'DeepSeek';
    default:
      return 'Moveo.AI';
  }
};

// Creates filter options for the language models table
export const languageModelFilterOptions = (
  value: string[],
  languageModels: LanguageModel[]
) => {
  return [
    ...languageModels.map((model) => ({
      text: model.model,
      value: model.language_model_id,
      icon: modelIconResolver(model.provider),
      checked: !!value.find((val) => val === model.language_model_id),
    })),
    {
      text: 'Moveo',
      value: 'Default',
      icon: <Brand color="var(--icon-default-blue)" />,
      checked: !!value.find((val) => val === 'Default'),
    },
  ];
};
