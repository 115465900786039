import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useTrackFormState } from '@/modules/aiAgents/hooks/useTrackFormState';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';

import { useSlider } from './useSlider';

import styles from './ConfidenceThreshold.module.scss';

export interface FormValues {
  confidence_threshold: number;
}

const FORM_ID = 'ai-agent-confidence-threshold';

export const ConfidenceThreshold = () => {
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const brainId = useSelector(selectBrainId);
  const { brain, getStatus, updateBrain, updateStatus } = useBrains(brainId);
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { isDirty },
  } = useForm<FormValues>({
    values: {
      confidence_threshold: brain?.confidence_threshold ?? 0.0,
    },
  });

  // Watch the confidence threshold value from form
  const value = watch('confidence_threshold');

  useTrackFormState({ isDirty, formId: FORM_ID });

  const onSubmit = useCallback(
    ({ confidence_threshold }: FormValues) => {
      updateBrain({
        brain_id: brainId,
        confidence_threshold,
      });
    },
    [brainId, updateBrain]
  );

  const {
    percentage,
    dots,
    trackRef,
    markerRef,
    handleTrackClick,
    handleMouseDown,
    handleKeyDown,
  } = useSlider({
    value,
    onChange: (value) =>
      setValue('confidence_threshold', value, {
        shouldDirty: true,
      }),
    disabled: !canWrite,
  });

  return (
    <>
      <FormFieldset
        title={t('ai_agents.advanced_settings.prediction_threshold.title')}
        subtitle={t(
          'ai_agents.advanced_settings.prediction_threshold.subtitle'
        )}
        primaryButton={{
          children: t('common.save'),
          disabled: !isDirty,
          isLoading: updateStatus === 'pending',
        }}
        onSubmit={handleSubmit(onSubmit)}
        helpText={t('ai_agents.advanced_settings.prediction_threshold.helper')}
        isLoading={getStatus === 'pending'}
      >
        <div className="flex items-center gap-6">
          <div className="w-full">
            {/* Confidence label */}
            <Typography
              component="h4"
              variant="label-caps-large"
              color="var(--text-default-gray)"
              mb="var(--space-8)"
            >
              {t('analytics.fields.confidence')}
            </Typography>

            {/* Slider track container */}
            <div
              ref={trackRef}
              className={cn(
                'relative h-6',
                // Disable cursor and reduce opacity if user cannot write
                canWrite ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
              )}
              onClick={handleTrackClick}
              role="slider"
              aria-valuenow={percentage}
              tabIndex={canWrite ? 0 : -1}
              onKeyDown={handleKeyDown}
              aria-disabled={!canWrite}
            >
              {/* Background track */}
              <div className={styles.backgroundTrack} />

              {/* Filled portion of track */}
              <div
                className={styles.filledTrack}
                style={{ width: `${percentage}%` }}
              />

              {/* Dots container */}
              <div className="absolute inset-0 flex justify-between items-center px-1">
                {dots.map((dot) => (
                  <div
                    key={dot.position}
                    className={cn(styles.dot, dot.active && styles.dotActive)}
                  />
                ))}
              </div>

              {/* Current value marker/handle */}
              <div
                ref={markerRef}
                onMouseDown={handleMouseDown}
                className={styles.currentValueMarker}
                role="slider"
                tabIndex={0}
                aria-valuenow={percentage}
                style={{
                  left: `${percentage}%`,
                  transform: `translate(-50%, -50%)`,
                }}
              />

              {/* Hidden input for form submission */}
              <input type="hidden" {...register('confidence_threshold')} />
            </div>

            {/* Labels for confidence levels */}
            <div className="flex justify-between mt-2">
              <Typography
                variant="label-caps-small"
                color="var(--text-placeholder-gray)"
              >
                {t('ai_agents.confidence_threshold.flexible')}
              </Typography>
              <Typography
                variant="label-caps-small"
                color="var(--text-placeholder-gray)"
              >
                {t('ai_agents.confidence_threshold.balanced')}
              </Typography>
              <Typography
                variant="label-caps-small"
                color="var(--text-placeholder-gray)"
              >
                {t('ai_agents.confidence_threshold.conservative')}
              </Typography>
            </div>
          </div>
          {/* Numeric display of current value */}
          <Typography
            className={styles.confidenceValue}
            variant="subheading-bold"
            color="var(--text-default-garay)"
          >
            {value.toFixed(2)}
          </Typography>
        </div>
      </FormFieldset>
    </>
  );
};
